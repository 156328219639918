import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  useRef,
} from "react";
import moment from "moment";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ReadingDownloadIdea from "../downloadIdea/readingDownloadIdea";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableContainer,
  Typography,
  Button,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  AppBar,
  FormGroup,
  Switch,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import firebase from "../../firebase/firebase";
import ReactPlayer from "react-player";
import InputBase from "@material-ui/core/InputBase";
import { SearchRounded, HighlightOff } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import {
  HOME_PAGE,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  TIME_FORMAT_SECONDS,
  STATUS_COMPLETED,
  STATUS_HOLD,
  STATUS_INCOMPLETE,
  replaceStringUsingRegex,
  roundToTwoDecimalPlaces,
  isImage,
  convertFirstCharacterToUppercase,
} from "../../utils/utils";
import {
  deleteDonationRequest,
  getAllDonationRequestsByFilter,
  updateDonationRequest,
  getJudges,
  getAllCountries,
  getUsers,
  getIdeasCount,
  updateConfigurations,
  getDonationRequest,
  getMentors,
  getStudentBlogList,
} from "../../utils/api";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import DonationRequestForm from "../donationRequest/DonationRequestForm";
import JudgesAllocation from "./JudgesAllocation";
import MentorAllocation from "./MentorAllocation";
import "./Admin.scss";
import styles from "./SubmittedIdeas.module.scss";
import Container from "@material-ui/core/Container";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import CheckIcon from "@material-ui/icons/Check";
import PersonIcon from "@material-ui/icons/Person";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import ViewApplication from "../Transformers/ViewApplication";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
  },

  table: {
    "& td:last-child": {
      whiteSpace: "nowrap",
    },
  },
  p: {
    margin: theme.spacing(1),
  },
  customWidth: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: "none",
  },

  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
const colors = [
  { key: "0", color: "#00a152", status: "Submitted" },
  { key: "-2", color: "#ffac33", status: "Saved" },
  { key: "-6", color: "#e65100", status: "On Hold" },
  { key: "2", color: "#00a152", status: "Submitted" },
];
const request = [
  { key: "cash", type: "Philanthropy" },
  { key: "social advocacy", type: "Social advocacy" },
  { key: "social entrepreneurship", type: "Social entrepreneurship" },
];
const SubmittedIdeas = (props) => {
  const classes = useStyles();
  const {
    authUser,
    isAdmin,
    isVolunteer,
    isSuperAdmin,
    isJudge,
    isStudent,
    isMentor,
    configuration,
    setConfiguration,
    configurationId,
    setConfigurationId,
  } = useContext(appContext);
  const keywordRef = useRef(null);
  const [donationRequests, setDonationRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenDonationRequestModal, setIsOpenDonationRequestModal] =
    useState(false);
  const [donationRequestId, setDonationRequestId] = useState();
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalUserRecords, setTotalUserRecords] = useState(0);
  const [sdgs, setSdgs] = useState("all");
  const [status, setStatus] = useState("all");
  const [allocationStatus, setAllocationStatus] = useState("all");
  const [country, setCountry] = useState("all");
  const [countries, setCountries] = useState([]);
  const [newest, setNewest] = useState(-1);
  const [radioMenu, setRadioMenu] = useState("");
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [openOrgModalforDownloadIdea, setOpenOrgModalforDownloadIdea] =
    useState(false);
  const defaultDonationRequestTypes = JSON.stringify([
    { request_type: "social advocacy" },
    { request_type: "social entrepreneurship" },
    { request_type: "cash", sourceOfCreation: "talscouts" },
  ]);
  const [sourceSubmittedFor, setSourceSubmittedFor] = useState("all");
  const [sourceTrackFilter, setSourceTrackFilter] = useState("all");
  const [ideaIds, setIdeaIds] = useState([]);
  const [judges, setJudges] = useState();
  const [judgeList, setJudgeList] = useState();
  const [mentorList, setMentorList] = useState();
  const [judgeId, setJudgeId] = useState(" ");
  const [rowData, setRowData] = useState({});
  const [isPrintpage, setIsPrintpage] = useState(false);
  const [ideasReviewed, setIdeasReviewed] = useState(false);
  const [ideasSavedCount, setIdeasSavedCount] = useState(0);
  const [ideasSubmittedCount, setIdeasSubmittedCount] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);
  const [users, setUsers] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [profile, setProfile] = useState("all");
  const [isConfiguration, setIsConfiguration] = useState(false);
  const [id, setId] = useState("");
  const [images, setImages] = useState([]);
  const [imageDocuments, setImageDocuments] = useState([]);
  const [nonImageDocuments, setNonImageDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const [isSocialEntrepreneur, setIsSocialEntrepreneur] = useState(false);
  const [isSocialPhilanthropy, setIsSocialPhilanthropy] = useState(false);
  const [assignedJudgeIdeaData, setAssignedJudgeIdeaData] = useState({});
  const [disableRating, setDisableRating] = useState(false);
  const [totalFieldsRatingsOfJudge, setTotalFieldsRatingsOfJudge] = useState(0);
  const [philonthophyRating, setPhilonthophyRating] = React.useState({
    title: "0",
    purposeOfFundraiser: "0",
    goalOfFundraiserAmount: "0",
    goalOfFundRaiser: "0",
    beneficiaryOfFundraiser: "0",
    socialImpactSolution: "0",
    keyTargetMetrics: "0",
    pitchDeck: "0",
  });
  const [advocasyRating, setAdvocasyRating] = React.useState({
    title: "0",
    socialProblem: "0",
    socialChangeImpact: "0",
    proposedSolution: "0",
    supportNeeded: "0",
    externalSupportNeeded: "0",
    pitchDeck: "0",
  });
  const [entrePrenuerShipRating, setentrePrenuerShipRating] = React.useState({
    title: "0",
    communityProblemAndSolution: "0",
    socialProblem: "0",
    solutionProposing: "0",
    targetImpact: "0",
    targetLives: "0",
    describeUVP: "0",
    targetPartners: "0",
    costStructure: "0",
    revenueStreams: "0",
    potentialRisks: "0",
    pitchDeck: "0",
  });
  const [isSocialAdvocacy, setIsSocialAdvocacy] = useState(false);
  const [isViewApplication, setIsViewApplication] = useState(false);
  const [openMentorOrgModal, setOpenMentorOrgModal] = useState(false);
  const [viewId, setViewId] = useState("");
  const [mentorId, setMentorId] = useState("");
  const [teamFilter, setTeamFilter] = useState("all");
  const [enableCertificateView, setEnableCertificateView] = useState(false);
  const [enableSubmitIdea, setEnableSubmitIdea] = useState(false);
  const [enableCreateIdea, setEnableCreateIdea] = useState(false);
  const [enableCreateTeam, setEnableCreateTeam] = useState(false);
  const [hold, setHold] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");
  const [userLoading, setUserLoading] = useState(false);
  const [requestsLoading, setRequestsLoading] = useState(false);
  const [final, setFinal] = useState(false);
  const [isJudgeShortList, setIsJudgeShortList] = useState(false);
  const [isJudgeApplication, setIsJudgeApplication] = useState(true);
  const [enableEdit, setEnableEdit] = useState(false);
  const [tabStatus, setTabStatus] = useState("");
  const [blogsResponse, setBlogsResponse] = useState([]);
  const [blogsCount, setBlogsCount] = useState(0);
  const [blogsLoader, setBlogsLoader] = useState(false);
  const [isFileDownload, setIsFileDownload] = useState(false);
  const loadDonationRequests = (id) => {
    setIsLoading(true);
    getDonationRequest(id)
      .then((response) => {
        if (response) {
          const userAge =
            response && response.user_info && response.user_info.dob;
          if (userAge) {
            response.user_info.dob = moment().diff(
              response.user_info.dob,
              "years"
            );
          }
          const linkDocument = response && response.pitchDeckVideoUrl;
          if (linkDocument) {
            response.pitchDeckVideoUrl = response.pitchDeckVideoUrl =
              !/^https?:\/\//i.test(response.pitchDeckVideoUrl)
                ? `http://${response.pitchDeckVideoUrl}`
                : response.pitchDeckVideoUrl;
          }
          setDocument(response);
          setId(response && response._id);
          if (response.request_type == "social advocacy")
            setIsSocialAdvocacy(true);
          else if (response.request_type == "social entrepreneurship")
            setIsSocialEntrepreneur(true);
          else if (response.request_type == "cash")
            setIsSocialPhilanthropy(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching DonationRequest:" + error);
      });
  };

  const loadJudges = () => {
    if (authUser && authUser.unique_id && document && document._id) {
      setIsLoading(true);
      getJudges(document._id, isJudge ? authUser.unique_id : "")
        .then((response) => {
          const respData = response.data;
          respData && setJudges([...respData]);
          if (response && response.data[0] && response.data[0]) {
            setAssignedJudgeIdeaData(response.data[0]);
            const judgeRatingObject = response.data[0].ratings;
            if (
              response.data[0].ratings &&
              Object.values(judgeRatingObject).every((v) => v > 0)
            ) {
              setPhilonthophyRating(response.data[0].ratings);
              setAdvocasyRating(response.data[0].ratings);
              setentrePrenuerShipRating(response.data[0].ratings);
              setDisableRating(true);
            }
          }
          setIsLoading(false);
        })
        .catch((error) => {
          toastr.error("Problem in fetching Judges: " + error.message);
        });
    }
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const onProfileChange = (event) => {
    setProfile(event.target.value);
  };

  const ideasCount = () => {
    setIsLoading(true);
    getIdeasCount(ideasStartDate)
      .then((response) => {
        if (response.data) {
          if (response.data.talscouts) {
            response.data.talscouts.map((data) => {
              if (data._id === -2) {
                setIdeasSavedCount(data.count);
              }
              if (data._id === 0) {
                setIdeasSubmittedCount(data.count);
              }
            });
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error(
          "Problem in fetching Submitted Ideas Count " + error.message
        );
      });
  };

  const loadConfiguration = () => {
    setEnableCertificateView(configuration.enableViewCertificate);
    setEnableCreateIdea(configuration.enableIdeaCreation);
    setEnableSubmitIdea(configuration.enableIdeaSubmission);
    setEnableCreateTeam(configuration.enableTeamCreation);
  };

  const handleConfiguration = () => {
    const payload = {
      eventId: null,
      enableViewCertificate: enableCertificateView,
      enableTeamCreation: enableCreateTeam,
      enableIdeaSubmission: enableSubmitIdea,
      enableIdeaCreation: enableCreateIdea,
    };
    if (isConfiguration) {
      updateConfigurations(configurationId, payload)
        .then((response) => {
          if (response.data) {
            setEnableCertificateView(response.data.data.enableViewCertificate);
            setEnableCreateIdea(response.data.data.enableIdeaCreation);
            setEnableSubmitIdea(response.data.data.enableIdeaSubmission);
            setEnableCreateTeam(response.data.data.enableTeamCreation);
            setConfigurationId(response.data.data._id);
            setConfiguration({
              ...configuration,
              eventId: null,
              enableViewCertificate: response.data.data.enableViewCertificate,
              enableTeamCreation: response.data.data.enableTeamCreation,
              enableIdeaSubmission: response.data.data.enableIdeaSubmission,
              enableIdeaCreation: response.data.data.enableIdeaCreation,
            });
            toastr.success("Configuration saved successfully");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error("Problem in saving configurations " + error.message);
        });
    }
  };
  useEffect(() => {
    ideasCount();
  }, []);

  if (refreshPage) {
    window.location.reload();
  }

  const reset = () => {
    setDonationRequests([]);
    setPage(0);
  };
  const handleIdeaId = (event) => {
    let currentValue = event.target.value;
    let isChecked = event.target.checked;
    if (isChecked) {
      setIdeaIds((preValues) => [...preValues, currentValue]);
      setRadioMenu("");
    } else {
      setIdeaIds((preValues) => {
        return preValues.filter((item) => item != currentValue);
      });
    }
  };
  const getStatus = (key) => {
    let obj = colors.filter((item) => item.key === key + "");
    if (obj.length > 0) {
      return obj[0].status;
    }
  };
  const getColor = (key) => {
    let obj = colors.filter((item) => item.key === key + "");
    if (obj.length > 0) {
      return obj[0].color;
    }
  };

  const getRequestType = (key) => {
    let obj = request.filter((item) => item.key === key + "");
    if (obj.length > 0) {
      return obj[0].type;
    }
  };

  const onChangeOrder = (event) => {
    reset();
    setNewest(event.target.value);
  };
  const onTeamChange = (e) => {
    reset();
    setTeamFilter(e.target.value);
  };
  const onTrackChange = (e) => {
    reset();
    setSourceTrackFilter(e.target.value);
  };

  const onSourceSubmittedChange = (e) => {
    reset();
    setSourceSubmittedFor(e.target.value);
  };

  const onStatusChange = (event) => {
    reset();
    setStatus(event.target.value);
    setStatusFilter(event.target.value);
  };
  const onSdgsChange = (event) => {
    reset();
    setSdgs(event.target.value);
  };
  const onCountryChange = (event) => {
    reset();
    setCountry(event.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    getAllCountries()
      .then((result) => {
        setCountries(result.data);
        setIsLoading(false);
      })
      .catch((error) => {
        toastr.error("Problem in getting Countries: " + error.message);
      });
  }, []);

  useEffect(() => {
    if (document._id) {
      loadJudges();
    }
  }, [document._id]);

  const fetchImages = (uid) => {
    return new Promise((resolve, reject) => {
      // Fetch images
      const path = `DonationRequest/${uid}/images`;
      const storageRef = firebase.storageDonationRequests.ref();
      const pathRef = storageRef.child(path);
      pathRef
        .listAll()
        .then((result) => {
          if (result.items && result.items.length > 0) {
            setImages([]);
            result.items.forEach((item) => {
              item.getDownloadURL().then((url) => {
                setImages((prevImages) => {
                  return [
                    ...prevImages,
                    {
                      original: url,
                      thumbnail: url,
                    },
                  ];
                });
              });
            });
            resolve();
          } else if (
            document.request_type !== "cash" &&
            document.request_type !== "social entrepreneurship" &&
            document.request_type !== "social advocacy"
          ) {
            setImages([
              {
                original: `${
                  document && document.defaultImageUrl
                    ? document.defaultImageUrl
                    : "/images/default-donation-request.jpg"
                }`,
                thumbnail: `${
                  document && document.defaultImageUrl
                    ? document.defaultImageUrl
                    : "/images/default-donation-request.jpg"
                }`,
              },
            ]);
            resolve();
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const _renderVideo = (item) => {
    return (
      <ReactPlayer
        url={item.embedUrl}
        playing={false}
        controls={true}
        width="100%"
        height="100%"
      />
    );
  };

  const fetchDocuments = (uid) => {
    return new Promise((resolve, reject) => {
      const path = `DonationRequest/${uid}/documents`;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef.listAll().then((result) => {
        setImageDocuments([]);
        setNonImageDocuments([]);
        result.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            const itemPathRef = storageRef.child(path + "/" + item.name);
            itemPathRef
              .getMetadata()
              .then((metadata) => {
                if (isImage(metadata.contentType)) {
                  setImageDocuments((prevDocuments) => [
                    ...prevDocuments,
                    {
                      url: url,
                      name: metadata.name,
                      contentType: metadata.contentType,
                      userGivenName: metadata.customMetadata
                        ? metadata.customMetadata.userGivenName
                        : metadata.name,
                      note:
                        metadata.customMetadata && metadata.customMetadata.note,
                    },
                  ]);
                } else {
                  setNonImageDocuments((prevDocuments) => [
                    ...prevDocuments,
                    {
                      url: url,
                      name: metadata.name,
                      contentType: metadata.contentType,
                      userGivenName: metadata.customMetadata
                        ? metadata.customMetadata.userGivenName
                        : metadata.name,
                      note:
                        metadata.customMetadata && metadata.customMetadata.note,
                    },
                  ]);
                }
              })
              .catch(() => {
                //toastr.error(error.message);
              });
          });
        });
        resolve();
      });
    });
  };

  const fetchVideos = (uid) => {
    return new Promise((resolve, reject) => {
      // Fetch videos
      const path = `DonationRequest/${uid}/videos`;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef
        .listAll()
        .then((result) => {
          if (result.items && result.items.length > 0) {
            result.items.forEach((item) => {
              item.getDownloadURL().then((url) => {
                setImages((prevImages) => {
                  return [
                    ...prevImages,
                    {
                      original: "/images/default-thumbnail.jpeg",
                      thumbnail: "/images/default-thumbnail.jpeg",
                      embedUrl: url,
                      renderItem: _renderVideo.bind(this),
                    },
                  ];
                });
              });
            });
            resolve();
          }
        })
        .catch(() => {});
    });
  };

  useEffect(() => {
    if (document && document._id) {
      fetchVideos(document._id);
      fetchImages(document._id);
      fetchDocuments(document._id);
    }
  }, [document]);

  const loadUsers = () => {
    setUserLoading(true);
    getUsers(
      "",
      "",
      "",
      "",
      "",
      fromDate ? new Date(fromDate).getTime() : "",
      toDate ? new Date(toDate).getTime() : "",
      profile === "onlycertificatedownload" ? true : false
    )
      .then((response) => {
        if (response) {
          setUsers(response.data);
          setTotalUserRecords(response.totalCountOfRecords);
        }
        setUserLoading(false);
      })
      .catch((error) => {
        setUserLoading(false);
        toastr.error("Problem in fetching users: " + error.message);
      });
  };
  const ideasStartDate = process.env.REACT_APP_IDEAS_DISPLAYING_DATE;
  const loadDonationRequestsBasedOnKeyword = (keyword, page) => {
    setRequestsLoading(true);
    let sourceOfCreation = "talscouts";
    getAllDonationRequestsByFilter(
      !(isJudge || isMentor) ? rowsPerPage : "100",
      !(isJudge || isMentor) ? page * rowsPerPage : "0",
      sourceTrackFilter === "all"
        ? defaultDonationRequestTypes
        : sourceTrackFilter,
      newest,
      status === "all" ? "" : status,
      sdgs === "all" ? "" : sdgs,
      judgeId ? judgeId && judgeId.trim() : "",
      allocationStatus === "all" ? "" : allocationStatus,
      country === "all" ? "" : country,
      keyword,
      sourceOfCreation,
      isJudge ? true : "",
      mentorId ? mentorId && mentorId.trim() : "",
      isMentor ? true : "",
      ideasStartDate,
      sourceSubmittedFor === "all" ? "" : sourceSubmittedFor
    )
      .then((response) => {
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          setTotalRecords(response.totalCountOfRecords);
          const ideasListArray = [];
          donationRequests.map((data) => {
            const obj = data;
            ideasListArray.push(obj);
            return null;
          });
          let responseArray = [];
          response.data.map((data) => {
            const obj = data;
            responseArray.push(obj);
            return null;
          });
          if (isJudge && isJudgeApplication) {
            responseArray = responseArray.filter(
              (document) =>
                !document.userRatings.includes(authUser && authUser.unique_id)
            );
            setTotalRecords(responseArray.length);
          }
          if (isJudge && isJudgeShortList) {
            responseArray = responseArray.filter(
              (document) =>
                document.avgRating > 0 &&
                document.userRatings.includes(authUser && authUser.unique_id)
            );
            setTotalRecords(responseArray.length);
          }
          setDonationRequests([...ideasListArray, ...responseArray]);
          setRequestsLoading(false);
        }
        setRequestsLoading(false);
      })
      .catch((error) => {
        setRequestsLoading(false);
        toastr.error("Problem in fetching Ideas: ", error.message);
      });
  };
  useEffect(() => {
    if (authUser && authUser.institutionalRole === "judge") {
      isJudge && setJudgeId(authUser && authUser.email);
      isJudge && setAllocationStatus("1");
      isJudge && setStatus("0,-2");
    } else {
      (isAdmin || isSuperAdmin) && loadJudges();
      setJudgeId(" ");
    }
    if (authUser && authUser.institutionalRole === "mentor") {
      isMentor && setMentorId(authUser && authUser.email);
      isMentor && setAllocationStatus("8");
      isMentor && setStatus("0,-2");
    } else {
      setMentorId("");
    }
  }, [authUser]);

  useEffect(() => {
    if (
      ((authUser && judgeId && judgeId.trim().length > 0) ||
        isAdmin ||
        (authUser && mentorId && mentorId.trim().length > 0)) &&
      allocationStatus !== "student-blog"
    ) {
      loadDonationRequestsBasedOnKeyword(keyword, page);
      if (!isMentor && !isJudge && allocationStatus === "all") loadUsers();
      if (isAdmin || isSuperAdmin) loadConfiguration();
    }
    if (authUser && isAdmin && allocationStatus === "student-blog") {
      loadStudentBlogs();
    }
  }, [
    judgeId,
    sourceTrackFilter,
    allocationStatus,
    status,
    sdgs,
    newest,
    country,
    keyword,
    rowsPerPage,
    page,
    isAdmin,
    fromDate,
    toDate,
    profile,
    mentorId,
    isViewApplication,
    hold,
    openOrgModal,
    openMentorOrgModal,
    final,
    enableEdit,
    sourceSubmittedFor,
  ]);

  useEffect(() => {
    if (authUser) {
      if (isAdmin || isVolunteer || isSuperAdmin || isJudge || isMentor) {
      } else {
        toastr.warning(
          "You do not have the privilege to access the requested page!"
        );
        props.history.push(HOME_PAGE);
      }
    }
  }, [authUser]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    reset();
  };

  const handleChangeBlogPage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeBlogRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setBlogsResponse([]);
  };

  const toDownload = (row) => {
    setRowData(row);
    setIsPrintpage(true);
    setOpenOrgModalforDownloadIdea(true);
  };

  const clearState = () => {
    reset();
    setStatus("0,-2");
    setStatusFilter("all");
    setCountry("all");
    setSdgs("all");
    setSourceTrackFilter("all");
    setNewest(-1);
    if (authUser && authUser.institutionalRole == "judge") {
      setJudgeId(authUser && authUser.email);
    } else {
      setJudgeId(" ");
    }
    if (authUser && authUser.isMentor) {
      setMentorId(authUser && authUser.email);
    } else {
      setMentorId("");
    }
    setSourceSubmittedFor("all");
    setKeyword("");
    setIsSearchMode(false);
    keywordRef.current.value = "";
  };

  const exportToCSV = () => {
    let sourceOfCreation = "talscouts";
    const JsonFields =
      allocationStatus === "all"
        ? [
            "First Name",
            "Last Name",
            "Email Address",
            "Account Status",
            "Ref Site",
            "Registered Event",
            "Role",
            "Date of Birth",
            "Institute Name",
            "Grade",
            "Institute Website",
            "Country",
            "State",
            "City",
            "Gender",
            "Mentor Requested",
            "Mentor First Name",
            "Mentor Last Name",
            "Mentor Phone",
            "Mentor Email",
            "Mentor Organization",
            "Mentor Org. Website",
            "Last Updated At",
          ]
        : [
            "Submitted By",
            "Submitted Email",
            "Submitted On",
            "Status",
            "Institution",
            "Mentor Name",
            "Mentor Phone Number",
            "Mentor Email",
            "Submitted For",
            "Track",
            "Assigned To",
            "Avg. Rating",
            "Title",
            "SDGs",
            "Proposed Solution",
            "Team Name",
            "Team Members",
            "Mobile Number",
          ];

    let csvStr = JsonFields.join(",") + "\n";
    let hiddenElement = window.document.createElement("a");
    if (allocationStatus === "all") {
      getUsers(
        "",
        "",
        "",
        "",
        "",
        fromDate ? new Date(fromDate).getTime() : "",
        toDate ? new Date(toDate).getTime() : "",
        profile === "onlycertificatedownload" ? true : false
      )
        .then((response) => {
          if (
            response.data &&
            response.data instanceof Array &&
            response.data.length >= 0
          ) {
            response.data.forEach((row) => {
              csvStr +=
                row.name.first_name.replace(/[,#]/g, "") +
                "," +
                row.name.last_name.replace(/[,#]/g, "") +
                "," +
                row.email +
                "," +
                (row.account_verified === true ? "Verified" : "Not Verified") +
                "," +
                (row.websiteReferenceCode !== null &&
                row.websiteReferenceCode !== undefined
                  ? row.websiteReferenceCode.replace(/[,#]/g, "")
                  : " ") +
                "," +
                row?.registeredForEvent +
                "," +
                row.institutionalRole +
                "," +
                (row.dob === 0 ? "" : new Date(row.dob).toLocaleDateString()) +
                "," +
                (row.institutionName
                  ? row.institutionName.replace(/[,#]/g, "")
                  : "") +
                "," +
                row.educationalQualification +
                "," +
                (row.institutionUrl
                  ? row.institutionUrl.replace(/[,#]/g, "")
                  : "") +
                "," +
                row.address.country +
                "," +
                row.address.state +
                "," +
                row.address.city +
                "," +
                row.gender +
                "," +
                (row.isMentor ? "Yes" : " ") +
                "," +
                (row.mentorInfo.firstName
                  ? row.mentorInfo.firstName.replace(/[,#]/g, "")
                  : "") +
                "," +
                (row.mentorInfo.lastName
                  ? row.mentorInfo.lastName.replace(/[,#]/g, "")
                  : "") +
                "," +
                row.mentorInfo.phone +
                "," +
                row.mentorInfo.email +
                "," +
                (row.mentorInfo.organization
                  ? row.mentorInfo.organization.replace(/[,#]/g, "")
                  : "") +
                "," +
                (row.mentorInfo.organizationWebsite
                  ? row.mentorInfo.organization.replace(/[,#]/g, "")
                  : "") +
                "," +
                moment(row.updatedAt).format(DATE_TIME_FORMAT) +
                "\n";
            });
            hiddenElement.href =
              "data:text/csv;charset=utf-8," + encodeURI(csvStr);
            hiddenElement.target = "_blank";
            hiddenElement.download =
              "Users" +
              `-${moment().format(
                `${DATE_FORMAT} ${TIME_FORMAT_SECONDS}`
              )}.csv`;
            hiddenElement.click();
          }
        })
        .catch((error) => {
          toastr.error("Problem in fetching users: " + error.message);
        });
    } else if (allocationStatus === "student-blog") {
      let download = true;
      setIsFileDownload(true);
      getStudentBlogList("", "", download)
        .then((resp) => {
          var hiddenElement = window.document.createElement("a");
          hiddenElement.href = `data:text/csv;charset=utf-8,${resp}`;
          hiddenElement.target = "_blank";
          hiddenElement.download = `Student_Blog-${new Date().getTime()}.csv`;
          hiddenElement.click();
          setIsFileDownload(false);
        })
        .catch((error) => {
          setIsFileDownload(false);
          toastr.error("Error while exporting data", error.message);
        });
    } else {
      getAllDonationRequestsByFilter(
        "",
        "",
        sourceTrackFilter === "all"
          ? defaultDonationRequestTypes
          : sourceTrackFilter,
        newest,
        status === "all" ? "" : status,
        sdgs === "all" ? "" : sdgs,
        judgeId ? judgeId && judgeId.trim() : "",
        allocationStatus === "all" ? "" : allocationStatus,
        country === "all" ? "" : country,
        keyword,
        sourceOfCreation,
        isJudge ? ideasReviewed : "",
        mentorId ? mentorId && mentorId.trim() : "",
        isMentor || isAdmin || isSuperAdmin,
        ideasStartDate,
        sourceSubmittedFor === "all" ? "" : sourceSubmittedFor
      )
        .then((response) => {
          if (
            response.data &&
            response.data instanceof Array &&
            response.data.length >= 0
          ) {
            response.data.forEach((element) => {
              let {
                submittedby,
                Title,
                status,
                submittedFor,
                mentorName,
                mentorPhone,
                mentorEmail,
                Track,
                email,
                createdAt,
                institutionName,
                assignedTo,
                avgRating,
                supportedSDGs,
                solutionProposing,
                teamId,
                team,
                phone,
              } = element;

              submittedby =
                element.user_info.name.first_name +
                element.user_info.name.last_name;
              status = getStatus(element.status);
              submittedFor = element.requested_for;
              mentorName =
                (element.user_info.mentorInfo.firstName
                  ? element.user_info.mentorInfo.firstName
                  : "") +
                " " +
                (element.user_info.mentorInfo.lastName
                  ? element.user_info.mentorInfo.lastName
                  : "");

              mentorPhone = element.user_info.mentorInfo.phone
                ? element.user_info.mentorInfo.firstName &&
                  element.user_info.mentorInfo.lastName
                  ? ","
                  : "" + element.user_info.mentorInfo.phone
                : "";
              mentorEmail = element.user_info.mentorInfo.email
                ? element.user_info.mentorInfo.phone
                  ? ","
                  : "" + element.user_info.mentorInfo.email
                : "";
              Track = element.request_type;
              Title = element.title;
              email = element.user_info.email;
              createdAt = element.createdAt;
              institutionName = element.user_info.institutionName;
              assignedTo = element.assignedJudges.map((data) => {
                return data.email;
              });
              avgRating = element.avgRating;
              supportedSDGs = element.supportedSDGs;
              solutionProposing =
                element.request_type == "social entrepreneurship"
                  ? element.solutionProposing
                  : element.solutionSustainableSocialImpact;
              teamId = element.teamId && teamId.name;
              team = element.assignedTeamMembers.map((data) => {
                return data.email;
              });
              phone = element.user_info.phone;

              if (!status) {
                status = "-N.A-";
              }

              if (!submittedby) {
                submittedby = "";
              }

              if (!mentorName) {
                mentorName = "-N.A-";
              } else {
                mentorName = replaceStringUsingRegex(mentorName);
              }

              if (!mentorPhone) {
                mentorPhone = "-N.A-";
              }

              if (!mentorEmail) {
                mentorEmail = "-N.A-";
              }

              if (!submittedFor) {
                submittedFor = "";
              }

              if (!Track) {
                Track = "";
              }

              if (!Title) {
                Title = "";
              } else {
                Title = replaceStringUsingRegex(Title);
              }

              if (!email) {
                email = "";
              }

              if (!createdAt) {
                createdAt = "";
              } else {
                createdAt = moment(createdAt).format("MMMM Do yyyy h:mm:ss a");
              }

              if (!institutionName) {
                institutionName = "";
              } else {
                institutionName = replaceStringUsingRegex(institutionName);
              }

              if (!assignedTo) {
                assignedTo = "";
              } else {
                assignedTo = assignedTo.join("|");
              }

              if (!avgRating) {
                avgRating = "";
              }

              if (supportedSDGs && supportedSDGs.length > 0) {
                supportedSDGs = supportedSDGs.join("|");
              } else {
                supportedSDGs = "";
              }

              if (!solutionProposing) {
                solutionProposing = "";
              } else {
                solutionProposing = solutionProposing.replace(/\,/g, ";");
                solutionProposing = solutionProposing.replace(
                  /(\r\n|\n|\r)/gm,
                  " "
                );
                solutionProposing = replaceStringUsingRegex(
                  solutionProposing,
                  ""
                );
              }

              if (!teamId) {
                teamId = "";
              } else {
                teamId = replaceStringUsingRegex(teamId);
              }

              if (!team) {
                team = "";
              } else {
                team = team.join("|");
              }

              if (!phone) {
                phone = "";
              }

              csvStr +=
                submittedby +
                "," +
                email +
                "," +
                createdAt +
                "," +
                status +
                "," +
                institutionName +
                "," +
                mentorName +
                "," +
                mentorPhone +
                "," +
                mentorEmail +
                "," +
                submittedFor +
                "," +
                Track +
                "," +
                assignedTo +
                "," +
                avgRating +
                "," +
                Title +
                "," +
                supportedSDGs +
                "," +
                solutionProposing +
                "," +
                teamId +
                "," +
                team +
                "," +
                phone +
                "\n";
            });
            hiddenElement.href =
              "data:text/csv;charset=utf-8," + encodeURI(csvStr);
            hiddenElement.target = "_blank";
            hiddenElement.download =
              "Ideas" +
              `-${moment().format(
                `${DATE_FORMAT} ${TIME_FORMAT_SECONDS}`
              )}.csv`;
            hiddenElement.click();
          }
        })
        .catch((error) => {
          toastr.error("Problem in fetching Ideas: ", error.message);
        });
    }
  };
  const onDeleteDonationRequest = (donationRequestId) => {
    if (window.confirm("Are you sure you want to delete the Idea?")) {
      deleteDonationRequest(donationRequestId, authUser.unique_id)
        .then(() => {
          // Update the list of donation requests
          setDonationRequests((donationRequests) => {
            return donationRequests.filter(
              (donationRequest) => donationRequest._id !== donationRequestId
            );
          });
          toastr.success("Idea is deleted successfully.");
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  const updateStatus = (statusCode = null, donationRequestId, status) => {
    if (statusCode === null) {
      toastr.error("Invalid status code5");
      return;
    }
    const finalValues = {};
    finalValues.status = statusCode;
    finalValues.user_id = authUser && authUser.unique_id;
    finalValues.prevStatus = status;
    updateDonationRequest(finalValues, donationRequestId)
      .then(() => {
        setDonationRequests([]);
        setHold(false);
        setFinal(false);
        setEnableEdit(false);
        toastr.success("Idea status updated successfully.");
      })
      .catch((error) => {
        toastr.error(error.message, "7");
      });
  };

  const updateSavedDonationRequest = (updatedDonationRequest) => {
    setDonationRequests((donationRequests) => {
      return donationRequests.map((donationRequest) => {
        if (donationRequest._id === updatedDonationRequest._id) {
          return {
            ...donationRequest,
            status: updatedDonationRequest.status,
            title: updatedDonationRequest.title,
            rank: updatedDonationRequest.rank,
          };
        }
        return donationRequest;
      });
    });
    setIsOpenDonationRequestModal(false);
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      if (event.target.value) {
        event.target.blur();
        setKeyword(event.target.value);
        setIsSearchMode(true);
        setDonationRequests([]);
      }
    }
  };

  const handleApplicationsTab = (newStatus) => {
    if (
      newStatus !== allocationStatus ||
      (newStatus === allocationStatus && isViewApplication)
    ) {
      setStatus("0,-2");
      setIsViewApplication(false);
      setDonationRequests([]);
      setAllocationStatus(isJudge ? "1" : isMentor ? "8" : "all");
      setIsJudgeShortList(false);
      setIsJudgeApplication(true);
    }
  };

  const handleShortListed = (newStatus) => {
    if (
      newStatus !== allocationStatus ||
      (newStatus === allocationStatus && isViewApplication)
    ) {
      setDonationRequests([]);
      setAllocationStatus("5");
      setStatus("0,-2");
      setIsViewApplication(false);
      setIsJudgeShortList(true);
      setIsJudgeApplication(false);
    }
  };

  const clearSearchResultsHandler = () => {
    keywordRef.current.value = "";
    setIsSearchMode(false);
    setKeyword("");
    reset();
  };
  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const handleJudgeAllocation = () => {
    setRadioMenu("");
    setOpenOrgModal(true);
    setDonationRequestId(donationRequestId);
  };
  const handleMentorAllocation = () => {
    setRadioMenu("");
    setOpenMentorOrgModal(true);
    setDonationRequestId(donationRequestId);
  };
  const onSavingOrg = (orgId) => {
    setOpenOrgModal(false);
    setRadioMenu("");
    props.history.push(`/organization/${orgId}`);
  };
  const onEditJudges = (donationRequestId) => {
    setRadioMenu("");
    setIdeaIds([donationRequestId]);
    getJudges(donationRequestId, "")
      .then((response) => {
        if (response) {
          const respData = response.data;
          setJudgeList([...respData]);
          setOpenOrgModal(true);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching Judges: " + error.message);
      });
  };
  const onEditMentors = (donationRequestId) => {
    setRadioMenu("");
    setIdeaIds([donationRequestId]);
    getMentors(donationRequestId)
      .then((response) => {
        if (response) {
          const respData = response.data;
          setMentorList([...respData]);
          setOpenMentorOrgModal(true);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching Mentors: " + error.message);
      });
  };
  function displayTable(
    donationRequests,
    isJudge,
    totalRecords,
    rowsPerPage,
    page
  ) {
    return (
      <div className={styles.manage_table}>
        {allocationStatus === "all" ? (
          <TableContainer
            className="admin-container table-fix"
            style={{ position: "unset" }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
              component="div"
              count={totalUserRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow className={styles.managetable}>
                  <TableCell>
                    <span>First Name</span>
                  </TableCell>
                  <TableCell>
                    <span>Last Name</span>
                  </TableCell>
                  <TableCell>
                    <span>Email</span>
                  </TableCell>
                  <TableCell>
                    <span> Account Status</span>
                  </TableCell>
                  <TableCell>
                    <span>Role</span>
                  </TableCell>
                  <TableCell>
                    <span>Site Ref</span>
                  </TableCell>
                  <TableCell>
                    <span>Registered Event</span>
                  </TableCell>
                  <TableCell>
                    <span>DOB</span>
                  </TableCell>
                  <TableCell>
                    <span>Institution Name</span>
                  </TableCell>
                  <TableCell>
                    <span>Grade/Cell</span>
                  </TableCell>
                  <TableCell>
                    <span> Institution Website</span>
                  </TableCell>
                  <TableCell>
                    <span> Country</span>
                  </TableCell>
                  <TableCell>
                    <span>State</span>
                  </TableCell>
                  <TableCell>
                    <span>City</span>
                  </TableCell>
                  <TableCell>
                    <span>Gender</span>
                  </TableCell>
                  <TableCell>
                    <span>Mentor Requested</span>
                  </TableCell>
                  <TableCell>
                    <span>Mentor First Name</span>
                  </TableCell>
                  <TableCell>
                    <span>Mentor Last Name</span>
                  </TableCell>
                  <TableCell>
                    <span>Mentor Phone</span>
                  </TableCell>
                  <TableCell>
                    <span>Mentor Email</span>
                  </TableCell>
                  <TableCell>
                    <span>Mentor Organization</span>
                  </TableCell>
                  <TableCell>
                    <span>Mentor Org. Website</span>
                  </TableCell>
                  <TableCell>
                    <span>Last Updated At</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
              //ref={infiniteRef}
              >
                {users &&
                  users.length > 0 &&
                  users
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell>
                            {row.name && row.name.first_name}
                          </TableCell>
                          <TableCell>
                            {row.name && row.name.last_name}
                          </TableCell>
                          <TableCell>{row && row.email}</TableCell>
                          <TableCell>
                            {row && row.account_verified === true
                              ? "Verified"
                              : "Not Verified"}
                          </TableCell>
                          <TableCell>
                            {convertFirstCharacterToUppercase(
                              row.institutionalRole
                            )}
                          </TableCell>
                          <TableCell>{row.websiteReferenceCode}</TableCell>
                          <TableCell>{row.registeredForEvent}</TableCell>
                          <TableCell>
                            {row.dob !== 0 &&
                              new Date(row.dob).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{row.institutionName}</TableCell>
                          <TableCell>{row.educationalQualification}</TableCell>
                          <TableCell>{row.institutionUrl}</TableCell>
                          <TableCell>
                            {row.address && row.address.country}
                          </TableCell>
                          <TableCell>
                            {row.address && row.address.state}
                          </TableCell>
                          <TableCell>
                            {row.address && row.address.city}
                          </TableCell>
                          <TableCell>{row.gender}</TableCell>
                          <TableCell>{row.isMentor}</TableCell>
                          <TableCell>
                            {row.mentorInfo && row.mentorInfo.firstName}
                          </TableCell>
                          <TableCell>
                            {row.mentorInfo && row.mentorInfo.lastName}
                          </TableCell>
                          <TableCell>
                            {row.mentorInfo && row.mentorInfo.phone}
                          </TableCell>
                          <TableCell>
                            {row.mentorInfo && row.mentorInfo.email}
                          </TableCell>
                          <TableCell>
                            {row.mentorInfo && row.mentorInfo.organization}
                          </TableCell>
                          <TableCell>
                            {row.mentorInfo &&
                              row.mentorInfo.organizationWebsite}
                          </TableCell>
                          <TableCell>
                            {moment(row.updatedAt).format(DATE_TIME_FORMAT)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {userLoading && (
                  <TableRow>
                    <TableCell colSpan={20}>
                      <Typography className="no-req-text">
                        Loading...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!userLoading && users && users.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={20}>
                      <Typography className="no-req-text">
                        No users Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
              component="div"
              count={totalUserRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        ) : (
          allocationStatus !== "student-blog" && (
            <TableContainer
              className={styles.admincontainer}
              style={{ position: "unset" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="sticky table"
                stickyHeader
              >
                <TableHead>
                  <TableRow className={styles.managetable}>
                    <TableCell>
                      <span>#</span>
                    </TableCell>
                    <TableCell>
                      <span>Submitted By</span>
                    </TableCell>
                    <TableCell>
                      <span>Submitted Email</span>
                    </TableCell>
                    <TableCell>
                      <span>Submitted On</span>
                    </TableCell>
                    {!isJudge && (
                      <TableCell>
                        <span>Status</span>
                      </TableCell>
                    )}
                    <TableCell>
                      <span>Institution</span>
                    </TableCell>
                    <TableCell>
                      <span>Mentor Information</span>
                    </TableCell>
                    <TableCell>
                      <span>Submitted For</span>
                    </TableCell>
                    <TableCell>
                      <span>Track</span>
                    </TableCell>
                    {!isJudge && (
                      <TableCell>
                        <span>Assigned To</span>
                      </TableCell>
                    )}
                    {!isJudge && (
                      <TableCell>
                        <span>Avg. Rating</span>
                      </TableCell>
                    )}
                    <TableCell>
                      <span>Title</span>
                    </TableCell>
                    <TableCell>
                      <span>SDGs</span>
                    </TableCell>
                    <TableCell>
                      <span>Team Name</span>
                    </TableCell>
                    <TableCell>
                      <span>Mobile Number</span>
                    </TableCell>
                    <TableCell>
                      <span>Location</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                //ref={infiniteRef}
                >
                  {donationRequests &&
                    donationRequests.length > 0 &&
                    donationRequests
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell>
                              <div className={styles.adminsrn}>
                                {isJudge && (
                                  <Tooltip title="Download Idea as PDF">
                                    <GetAppRoundedIcon
                                      onClick={() => {
                                        toDownload(row, row.request_type);
                                      }}
                                    />
                                  </Tooltip>
                                )}

                                {!isJudge &&
                                  status &&
                                  status != -6 &&
                                  allocationStatus === "2" && (
                                    <Tooltip title="Allocate judges">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="ideaId"
                                            value={row._id}
                                            checked={ideaIds.includes(row._id)}
                                            onChange={handleIdeaId}
                                          />
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                {(!isMentor || isAdmin || isSuperAdmin) &&
                                  status &&
                                  status != -6 &&
                                  allocationStatus === "9" && (
                                    <Tooltip title="Allocate Mentor">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="ideaId"
                                            value={row._id}
                                            checked={ideaIds.includes(row._id)}
                                            onChange={handleIdeaId}
                                          />
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                <Tooltip title="View submitted Application">
                                  <RemoveRedEyeOutlinedIcon
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setViewId(row._id);
                                      setDonationRequests([]);
                                      setIsViewApplication(true);
                                      loadDonationRequests(row._id);
                                    }}
                                  />
                                </Tooltip>
                                {!isJudge && (
                                  <Tooltip title="Delete">
                                    <IconButton
                                      className="del-btn"
                                      onClick={() =>
                                        onDeleteDonationRequest(row._id)
                                      }
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              {row.user_info.name.first_name}{" "}
                              {row.user_info.name.last_name}
                            </TableCell>
                            <TableCell>{row.user_info.email}</TableCell>
                            <TableCell>
                              {" "}
                              {moment(row.createdAt).format(DATE_TIME_FORMAT)}
                            </TableCell>
                            {!isJudge && (
                              <TableCell className="admin-status">
                                {" "}
                                <div>
                                  <Chip
                                    label={getStatus(row.status)}
                                    style={{
                                      background: getColor(row.status),
                                    }}
                                  />
                                </div>
                                <div>
                                  {status &&
                                    status != -6 &&
                                    (row.status == 0 ||
                                      row.status == 2 ||
                                      row.status == -2) && (
                                      <Tooltip title="Keep On Hold">
                                        <SubdirectoryArrowRightIcon
                                          onClick={() => {
                                            updateStatus(
                                              STATUS_HOLD,
                                              row._id,
                                              row.status
                                            );
                                            setAllocationStatus("");
                                            setDonationRequests([]);
                                            setStatus("-6");
                                            setHold(true);
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  {row &&
                                    row.assignedJudges &&
                                    row.avgRating > 0 &&
                                    row.status !== -6 &&
                                    allocationStatus === "5" && (
                                      <Tooltip title="Move to Final">
                                        <CheckIcon
                                          onClick={() => {
                                            setDonationRequests([]);
                                            setFinal(true);
                                            updateStatus(
                                              STATUS_COMPLETED,
                                              row._id
                                            );
                                          }}
                                        />
                                      </Tooltip>
                                    )}

                                  {(allocationStatus === "5" ||
                                    allocationStatus === "1") && (
                                    <Tooltip title="Manage Judges">
                                      <IconButton
                                        className="del-btn"
                                        onClick={() => onEditJudges(row._id)}
                                      >
                                        <PersonIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {allocationStatus === "8" && (
                                    <Tooltip title="Manage Mentors">
                                      <IconButton
                                        className="del-btn"
                                        onClick={() => onEditMentors(row._id)}
                                      >
                                        <PersonIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}

                                  {row.status == -6 && (
                                    <Tooltip title="Remove From Hold">
                                      <ReplyOutlinedIcon
                                        onClick={() => {
                                          updateStatus(row.prevStatus, row._id);
                                          setDonationRequests([]);
                                          setStatus(row.prevStatus);
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                  {(status && status === -6) ||
                                    ((row.status === 0 || row.status === 2) &&
                                      allocationStatus &&
                                      (allocationStatus === "2" ||
                                        allocationStatus === "9") && (
                                        <Tooltip title="Enable Editing">
                                          <LockOpenOutlinedIcon
                                            onClick={() => {
                                              updateStatus(
                                                STATUS_INCOMPLETE,
                                                row._id
                                              );
                                              setEnableEdit(true);
                                            }}
                                          />
                                        </Tooltip>
                                      ))}

                                  {(row.status === 0 ||
                                    row.status === 2 ||
                                    row.status === -2) && (
                                    <Tooltip title="Download Idea as PDF">
                                      <GetAppRoundedIcon
                                        onClick={() => {
                                          toDownload(row);
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              </TableCell>
                            )}

                            <TableCell>
                              {row.user_info.institutionName}
                            </TableCell>
                            <TableCell>
                              {row &&
                                row.assignedMentors &&
                                row.assignedMentors.length > 0 &&
                                row.assignedMentors.map((data, index) => {
                                  return (
                                    <span key={index}>
                                      {data.email}
                                      {index < row.assignedMentors.length - 1 &&
                                        ", "}
                                    </span>
                                  );
                                })}
                            </TableCell>
                            <TableCell>{row.requested_for}</TableCell>
                            <TableCell>
                              {getRequestType(row.request_type)}
                            </TableCell>
                            {!isJudge &&
                            row.assignedJudges &&
                            row.assignedJudges.length > 0 ? (
                              <TableCell>
                                {" "}
                                <Grid item md={8} xs={12}>
                                  <>
                                    <Grid container style={{ gap: "5px" }}>
                                      {row.assignedJudges.map((data) => {
                                        return <p>{data.email} </p>;
                                      })}
                                    </Grid>
                                  </>
                                </Grid>
                              </TableCell>
                            ) : (
                              !isJudge && (
                                <TableCell>
                                  {" "}
                                  <Grid item md={8} xs={12}>
                                    <>
                                      <Grid container style={{ gap: "5px" }}>
                                        <p>Not Assigned</p>
                                      </Grid>
                                    </>
                                  </Grid>
                                </TableCell>
                              )
                            )}

                            {!isJudge && (
                              <TableCell>
                                {roundToTwoDecimalPlaces(row.avgRating)}
                              </TableCell>
                            )}
                            <TableCell>{row.title}</TableCell>
                            <TableCell>
                              {" "}
                              <Grid item md={8} xs={12} className="text-ucase">
                                <>
                                  <Grid container style={{ gap: "5px" }}>
                                    {row &&
                                      row.supportedSDGs &&
                                      row.supportedSDGs.length > 0 &&
                                      row.supportedSDGs.map((data) => {
                                        return (
                                          <Chip
                                            className=" bg-secondary"
                                            label={data}
                                            style={{ color: "#fff" }}
                                            variant="outlined"
                                          />
                                        );
                                      })}
                                  </Grid>
                                </>
                              </Grid>
                            </TableCell>
                            {row && row.teamId && row.teamId.name ? (
                              <TableCell>{row.teamId.name}</TableCell>
                            ) : (
                              <TableCell> {""} </TableCell>
                            )}
                            <TableCell>{row.user_info.phone}</TableCell>
                            <TableCell>
                              {row.shipping_address.country},
                              {row.shipping_address.state},
                              {row.shipping_address.city}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                  {requestsLoading && (
                    <TableRow>
                      <TableCell colSpan={20}>
                        <Typography className="no-req-text">
                          Loading...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {!requestsLoading &&
                    donationRequests &&
                    donationRequests.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={20}>
                          <Typography className="no-req-text">
                            No Ideas Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          )
        )}
        {allocationStatus === "student-blog" && (
          <TableContainer
            className="admin-container table-fix"
            style={{ position: "unset" }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
              component="div"
              count={blogsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangeBlogPage}
              onChangeRowsPerPage={handleChangeBlogRowsPerPage}
            />
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="sticky table"
              stickyHeader
            >
              <TableHead>
                <TableRow className={styles.managetable}>
                  <TableCell>
                    <span>Submitted By</span>
                  </TableCell>
                  <TableCell>
                    <span>Email</span>
                  </TableCell>
                  <TableCell>
                    <span>Submitted On</span>
                  </TableCell>
                  <TableCell>
                    <span>Institution Name</span>
                  </TableCell>
                  <TableCell>
                    <span>Submitted for</span>
                  </TableCell>
                  <TableCell>
                    <span>SDG</span>
                  </TableCell>
                  <TableCell>
                    <span>Mobile Number</span>
                  </TableCell>
                  <TableCell>
                    <span>Download</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {blogsResponse &&
                  blogsResponse.length > 0 &&
                  blogsResponse
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell>
                            {row?.userId?.name?.first_name}{" "}
                            {row?.userId?.name?.middle_name}{" "}
                            {row?.userId?.name?.last_name}
                          </TableCell>
                          <TableCell>{row?.userId?.email}</TableCell>
                          <TableCell>
                            {moment(row?.createdAt).format(DATE_TIME_FORMAT)}
                          </TableCell>
                          <TableCell>{row?.userId?.institutionName}</TableCell>
                          <TableCell>
                            {row?.request_type === "student-blog"
                              ? "Student Blog"
                              : ""}
                          </TableCell>
                          <TableCell>No Poverty</TableCell>
                          <TableCell>{row?.userId?.phone}</TableCell>
                          <TableCell>
                            <Tooltip title="Download Blog as PDF">
                              <a
                                style={{ color: "blue" }}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={row?.documentUrl}
                              >
                                <GetAppRoundedIcon />
                              </a>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {blogsLoader && (
                  <TableRow>
                    <TableCell colSpan={20}>
                      <Typography className="no-req-text">
                        Loading...
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!blogsLoader &&
                  blogsResponse &&
                  blogsResponse.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={20}>
                        <Typography className="no-req-text">
                          No blogs found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
              component="div"
              count={blogsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangeBlogPage}
              onChangeRowsPerPage={handleChangeBlogRowsPerPage}
            />
          </TableContainer>
        )}
      </div>
    );
  }

  const handleBackClick = () => {
    reset();
    setIsViewApplication(false);
  };

  function viewApplication() {
    return (
      <ViewApplication
        donationRequestId={viewId}
        onBackClick={handleBackClick}
      />
    );
  }

  const loadStudentBlogs = () => {
    setBlogsLoader(true);
    getStudentBlogList(rowsPerPage, page * rowsPerPage)
      .then((response) => {
        if (response?.data?.length > 0) {
          setBlogsCount(response?.totalCountOfRecords);
          const blogsListArray = [];
          blogsResponse.map((data) => {
            const obj = data;
            blogsListArray.push(obj);
            return null;
          });
          let responseArray = [];
          response.data.map((data) => {
            const obj = data;
            responseArray.push(obj);
            return null;
          });
          setBlogsResponse([...blogsListArray, ...responseArray]);
          setBlogsLoader(false);
        }
      })
      .catch((error) => {
        setBlogsLoader(false);
        toastr.error("Error getting blogs", error.message);
      });
  };

  const handleStudentBlog = (stat) => {
    if (allocationStatus !== stat) {
      setTabStatus(stat);
      setAllocationStatus(stat);
      setBlogsResponse([]);
      setPage(0);
    }
  };

  const handleChangeStatus = (stat) => {
    setTabStatus(stat);
    if (
      allocationStatus !== stat ||
      (allocationStatus === stat && isViewApplication)
    ) {
      if (stat === "-6" || stat === "-2") {
        stat === "-6" && setStatus(stat);
        stat === "-2" && setStatus("2");
        setIsConfiguration(false);
        setAllocationStatus("");
      } else if (stat === "12") {
        setStatus(tabStatus);
        setIsConfiguration(true);
        setAllocationStatus(stat);
      } else {
        setAllocationStatus(stat);
        if (statusFilter === "all") {
          setStatus("0,-2");
        } else {
          setStatus(statusFilter);
        }
        setIsConfiguration(false);
      }
      setPage(0);
      setDonationRequests([]);
      setIsViewApplication(false);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div className={styles.barnav}>
        <Container MaxWidth="lg">
          <div className="search-right">
            <div className={styles.admin_head}>
              {authUser && (
                <Button
                  onClick={handleCloseSideMenu}
                  className={styles.managebtn}
                >
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
              <h2>
                {authUser && authUser.institutionalRole == "judge"
                  ? "Judge ideas"
                  : isMentor
                  ? "Mentor Ideas"
                  : "Manage Submitted Ideas"}
              </h2>
            </div>
            <AppBar className={styles.managetopbar}>
              {!isMentor && !isJudge && (
                <Paper>
                  <Grid className={styles.manage_topbar}>
                    <Button
                      className={
                        allocationStatus === "all"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => {
                        setAllocationStatus("all");
                        setDonationRequests([]);
                        setIsConfiguration(false);
                        setIsViewApplication(false);
                        loadUsers();
                      }}
                    >
                      All Profiles
                    </Button>

                    {/* <Button
                      className={
                        allocationStatus === "student-blog"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => handleStudentBlog("student-blog")}
                    >
                      Student Blog
                    </Button> */}

                    <Button
                      className={
                        allocationStatus === "9"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => handleChangeStatus("9")}
                    >
                      Mentor Un-allocated
                    </Button>

                    <Button
                      className={
                        allocationStatus === "8"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => handleChangeStatus("8")}
                    >
                      Mentor Allocated
                    </Button>

                    <Button
                      className={
                        allocationStatus === "2"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => handleChangeStatus("2")}
                    >
                      Judge Un-allocated
                    </Button>

                    <Button
                      className={
                        allocationStatus === "1"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => handleChangeStatus("1")}
                    >
                      Judge Allocated
                    </Button>

                    <Button
                      className={
                        allocationStatus === "5"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => handleChangeStatus("5")}
                    >
                      Judge Reviewed
                    </Button>

                    <Button
                      className={
                        status === "-6"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => handleChangeStatus("-6")}
                    >
                      On Hold
                    </Button>

                    <Button
                      className={
                        status === "2"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => handleChangeStatus("-2")}
                    >
                      Finalized
                    </Button>

                    <Button
                      className={
                        allocationStatus === "12"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => handleChangeStatus("12")}
                    >
                      Configurations
                    </Button>
                  </Grid>
                </Paper>
              )}
              {(isMentor || isJudge) && (
                <Paper className={styles.manage_app}>
                  <Button
                    className={
                      allocationStatus !== "5"
                        ? styles.manage_btn_active
                        : styles.manage_btn
                    }
                    onClick={() => {
                      handleApplicationsTab(
                        isJudge ? "1" : isMentor ? "8" : "all"
                      );
                    }}
                  >
                    Applications
                  </Button>
                  {isJudge && (
                    <Button
                      className={
                        allocationStatus === "5"
                          ? styles.manage_btn_active
                          : styles.manage_btn
                      }
                      onClick={() => {
                        handleShortListed("5");
                      }}
                    >
                      Shortlisted
                    </Button>
                  )}
                </Paper>
              )}
            </AppBar>
          </div>
          <div className={styles.searchadminall} style={{ margin: "10px" }}>
            {allocationStatus === "all" &&
            allocationStatus !== "student-blog" &&
            !isMentor &&
            !isJudge &&
            !isConfiguration ? (
              <>
                <FormControl variant="filled" className={styles.adminselect}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      showTodayButton
                      format="dd-MMM-yyyy"
                      style={{ width: "100%" }}
                      margin="normal"
                      className={`input-field dob`}
                      inputVariant="outlined"
                      label="Profile Updated From Date"
                      name="fromDate"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl variant="filled" className={styles.adminselect}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      showTodayButton
                      format="dd-MMM-yyyy"
                      style={{ width: "100%" }}
                      margin="normal"
                      className={`input-field dob`}
                      inputVariant="outlined"
                      label="Profile Updated To Date"
                      name="toDate"
                      value={toDate}
                      onChange={handleToDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl
                  variant="filled"
                  className={styles.admin_select}
                  style={{ width: "150px" }}
                >
                  <InputLabel htmlFor="Select Payment mode">
                    Profile Status
                  </InputLabel>
                  <Select
                    variant="outlined"
                    name="profile"
                    value={profile}
                    label="Select profile"
                    onChange={onProfileChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="onlycertificatedownload">
                      Only Certificate Download
                    </MenuItem>
                  </Select>
                </FormControl>{" "}
              </>
            ) : (
              allocationStatus !== "all" &&
              allocationStatus !== "student-blog" &&
              !isViewApplication &&
              !isMentor &&
              !isJudge &&
              !isConfiguration && (
                <>
                  {" "}
                  <FormControl variant="filled" className={styles.admin_select}>
                    <InputLabel htmlFor="Select Payment mode">
                      Select Sort Order
                    </InputLabel>
                    <Select
                      variant="outlined"
                      name="newest"
                      value={newest}
                      label="Select Sort Order"
                      onChange={onChangeOrder}
                    >
                      <MenuItem value="-1">Newest First</MenuItem>
                      <MenuItem value="1">Oldest First </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="filled" className={styles.admin_select}>
                    <InputLabel htmlFor="Select Payment mode">
                      Select Track
                    </InputLabel>
                    <Select
                      variant="outlined"
                      name="sourceTrackFilter"
                      value={sourceTrackFilter}
                      label="Select Track"
                      onChange={onTrackChange}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="social advocacy">
                        Social Advocacy
                      </MenuItem>
                      <MenuItem value="social entrepreneurship">
                        Social Entreprenuership
                      </MenuItem>
                      <MenuItem value="cash">Social Philanthropy</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="filled"
                    className={styles.admin_select}
                    style={{ width: "150px" }}
                  >
                    <InputLabel htmlFor="Select Payment mode">
                      Select SDG
                    </InputLabel>
                    <Select
                      variant="outlined"
                      name="sdgs"
                      value={sdgs}
                      label="Select sdgs"
                      onChange={onSdgsChange}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="No Poverty">1-No Poverty</MenuItem>
                      <MenuItem value="No Hunger">2-No Hunger</MenuItem>
                      <MenuItem value="Good Health">3-Good Health</MenuItem>
                      <MenuItem value="Innovation and Infrastructure">
                        4-Innovation and Infrastructure
                      </MenuItem>
                      <MenuItem value="Renewable Energy">
                        5-Renewable Energy
                      </MenuItem>
                      <MenuItem value="Good Jobs and Economic Growth">
                        6-Good Jobs and Econamic Growth
                      </MenuItem>
                      <MenuItem value="Gender Equality">
                        7-Gender Equality
                      </MenuItem>
                      <MenuItem value="Clean Water and Sanitization">
                        8-Clean Water and Sanitization
                      </MenuItem>
                      <MenuItem value="Responsible Consumption">
                        9-Responsible Consumption{" "}
                      </MenuItem>
                      <MenuItem value="Climate Action">
                        10-Climate Action
                      </MenuItem>
                      <MenuItem value="Life Below Water">
                        11-Life Below Water
                      </MenuItem>
                      <MenuItem value="Reduced Inequalities">
                        12-Reduced Inequalities
                      </MenuItem>
                      <MenuItem value="Partnerships For the Goals">
                        13-Partnership For the Goals
                      </MenuItem>
                      <MenuItem value="Sustainable Cities and Communities">
                        14-Sustainable Cities and Communities
                      </MenuItem>
                      <MenuItem value="Life on Lands">15-Life on Land</MenuItem>
                      <MenuItem value="Peace and Justice">
                        16-Peace and Justice
                      </MenuItem>
                      <MenuItem value="Quality Education">
                        17-Quality Education
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {!isJudge && (
                    <FormControl
                      variant="filled"
                      className={styles.admin_select}
                    >
                      <InputLabel htmlFor="Select Payment mode">
                        Select Status
                      </InputLabel>
                      <Select
                        variant="outlined"
                        name="status"
                        value={statusFilter}
                        label=" Select status"
                        onChange={onStatusChange}
                        disabled={tabStatus === "-6" || tabStatus === "-2"}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="-2">SAVED</MenuItem>
                        <MenuItem value="0">SUBMITTED</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  <FormControl variant="filled" className={styles.admin_select}>
                    <InputLabel htmlFor="Select Payment mode">
                      Select Country
                    </InputLabel>
                    <Select
                      variant="outlined"
                      name="countries"
                      value={country}
                      label="Select country"
                      onChange={onCountryChange}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {countries &&
                        countries.length > 0 &&
                        countries.map((country, index) => {
                          return (
                            <MenuItem value={country.name} key={index}>
                              {country.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <FormControl variant="filled" className={styles.mentorselect}>
                    <InputLabel htmlFor="Select Track">
                      Select Submitted For
                    </InputLabel>
                    <Select
                      variant="outlined"
                      name="sourceSubmittedFor"
                      value={sourceSubmittedFor}
                      label="Select Submitted For"
                      onChange={onSourceSubmittedChange}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="TALYouth Boot Camp On TALKindness Day 2024">
                        TALYouth Boot Camp On TALKindness Day 2024
                      </MenuItem>
                      <MenuItem value="Social Entrepreneurship Boot Camp">
                        Social Entrepreneurship Boot Camp
                      </MenuItem>
                      <MenuItem value="TALTransformers Global Youth Social Innovation Challenge 2024 - Climate Tech">
                        TALTransformers Global Youth Social Innovation Challenge
                        2024 - Climate Tech
                      </MenuItem>
                      <MenuItem value="TALTransformers Global Youth Social Innovation Challenge on Health Tech">
                        TALTransformers Global Youth Social Innovation Challenge
                        on Health Tech
                      </MenuItem>
                      <MenuItem value="ATA-TALTransformers Entrepreneurship Pitch Fest 2024">
                        ATA-TALTransformers Entrepreneurship Pitch Fest 2024
                      </MenuItem>
                      <MenuItem value="TTA TALTransformers Youth Social Entrepreneurship Pitch Fest 2024">
                        TTA TALTransformers Youth Social Entrepreneurship Pitch
                        Fest 2024
                      </MenuItem>
                      <MenuItem value="TALTransformers Global Youth Social Innovation Challenge on Agri Tech">
                        TALTransformers Global Youth Social Innovation Challenge
                        on Agri Tech
                      </MenuItem>
                      {/* <MenuItem value="student-blog">Student Blog</MenuItem> */}
                    </Select>
                  </FormControl>
                  <div className={styles.searchbar}>
                    <div className={styles.search_admin}>
                      <InputBase
                        placeholder="Search"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputRef={keywordRef}
                        inputProps={{ "aria-label": "search" }}
                        onKeyPress={(e) => handleEnterClick(e)}
                      />
                      <SearchRounded
                        style={{ margin: "0 4px", height: "30px" }}
                      />
                    </div>

                    {isSearchMode && (
                      <p className={styles.result}>
                        Search Results for
                        <span
                          style={{
                            padding: "3px 10px 5px 10px",
                            marginLeft: "10px",
                            fontWeight: " bold",
                            color: "#686868",
                            background: "#f8f8f8",
                          }}
                        >
                          {keyword}
                        </span>
                        <IconButton
                          edge="start"
                          aria-label="close"
                          style={{
                            padding: "3px",
                            color: "rgb(205, 38, 39)",
                            position: "relative",
                            top: "-2px",
                            margin: "0 0 0 10px",
                            border: "1px solid #ededed",
                          }}
                        >
                          <Tooltip title="Clear Search Results">
                            <CloseIcon onClick={clearSearchResultsHandler} />
                          </Tooltip>
                        </IconButton>
                      </p>
                    )}
                  </div>
                  <div>
                    {ideaIds && ideaIds.length > 0 && (
                      <Button
                        style={{
                          borderRadius: "5px",
                          border: "none",
                          color: "white",
                          height: "55px",
                          margin: "0",
                          backgroundColor: "var(--tal_primary) ",
                        }}
                        onClick={
                          allocationStatus === "9"
                            ? handleMentorAllocation
                            : allocationStatus === "2" && handleJudgeAllocation
                        }
                      >
                        <CheckIcon />
                        {allocationStatus === "9"
                          ? "Allocate Mentor"
                          : allocationStatus === "2" && "Allocate Judge"}
                      </Button>
                    )}
                  </div>
                </>
              )
            )}

            {!isConfiguration &&
              !isViewApplication &&
              !isMentor &&
              !isJudge &&
              allocationStatus !== "student-blog" && (
                <>
                  {" "}
                  <div>
                    {" "}
                    <Button
                      startIcon={<FileCopyOutlinedIcon />}
                      onClick={() => exportToCSV()}
                      style={{
                        borderRadius: "5px",
                        border: "none",
                        color: "white",
                        height: "55px",
                        margin: "0",
                        backgroundColor: "var(--tal_primary) ",
                      }}
                    >
                      Export Data
                    </Button>
                  </div>
                  <FormControl style={{ cursor: "pointer" }}>
                    <Button className={styles.clearbtn} onClick={clearState}>
                      <HighlightOff />
                      Clear All Filters
                    </Button>
                  </FormControl>
                </>
              )}

            {allocationStatus === "student-blog" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button
                  startIcon={<FileCopyOutlinedIcon />}
                  onClick={() => exportToCSV()}
                  style={{
                    borderRadius: "5px",
                    border: "none",
                    color: "white",
                    height: "55px",
                    margin: "0",
                    backgroundColor: "var(--tal_primary) ",
                  }}
                >
                  {!isFileDownload && "Export Data"}
                  {isFileDownload && (
                    <CircularProgress
                      style={{ color: "white", lineHeight: "10px" }}
                    />
                  )}
                </Button>
              </div>
            )}

            {(isMentor || isJudge) && !isViewApplication && (
              <>
                {" "}
                <div>
                  {" "}
                  <h5>Applications: {totalRecords}</h5>
                </div>
                <FormControl variant="filled" className={styles.mentorselect}>
                  <InputLabel htmlFor="Select Sort Order">
                    Select Sort Order
                  </InputLabel>
                  <Select
                    variant="outlined"
                    name="newest"
                    value={newest}
                    label="Select Sort Order"
                    onChange={onChangeOrder}
                  >
                    <MenuItem value="-1">Newest First</MenuItem>
                    <MenuItem value="1">Oldest First </MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" className={styles.mentorselect}>
                  <InputLabel htmlFor="Select Track">Select Track</InputLabel>
                  <Select
                    variant="outlined"
                    name="sourceTrackFilter"
                    value={sourceTrackFilter}
                    label="Select Track"
                    onChange={onTrackChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="social advocacy">Social Advocacy</MenuItem>
                    <MenuItem value="social entrepreneurship">
                      Social Entreprenuership
                    </MenuItem>
                    <MenuItem value="cash">Social Philanthropy</MenuItem>
                  </Select>
                </FormControl>
                {/* <FormControl variant="filled" className={styles.mentorselect}>
                  <InputLabel htmlFor="Select Team Size">
                    Select Team Size
                  </InputLabel>
                  <Select
                    variant="outlined"
                    name="teamFilter"
                    value={teamFilter}
                    label="Select Team"
                    onChange={onTeamChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="1-5">1-5</MenuItem>
                    <MenuItem value="5-10">5-10</MenuItem>
                    <MenuItem value="10-15">10-15</MenuItem>
                    <MenuItem value=">15">More than 15</MenuItem>
                  </Select>
                </FormControl> */}
                <FormControl variant="filled" className={styles.mentorselect}>
                  <InputLabel htmlFor="Select Country">
                    Select Country
                  </InputLabel>
                  <Select
                    variant="outlined"
                    name="countries"
                    value={country}
                    label="Select country"
                    onChange={onCountryChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {countries &&
                      countries.length > 0 &&
                      countries.map((country, index) => {
                        return (
                          <MenuItem value={country.name} key={index}>
                            {country.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl variant="filled" className={styles.mentorselect}>
                  <InputLabel htmlFor="Select Track">
                    Select Submitted For
                  </InputLabel>
                  <Select
                    variant="outlined"
                    name="sourceSubmittedFor"
                    value={sourceSubmittedFor}
                    label="Select Submitted For"
                    onChange={onSourceSubmittedChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="TALYouth Boot Camp On TALKindness Day 2024">
                      TALYouth Boot Camp On TALKindness Day 2024
                    </MenuItem>
                    <MenuItem value="Social Entrepreneurship Boot Camp">
                      Social Entrepreneurship Boot Camp
                    </MenuItem>
                    <MenuItem value="TALTransformers Global Youth Social Innovation Challenge 2024 - Climate Tech">
                      TALTransformers Global Youth Social Innovation Challenge
                      2024 - Climate Tech
                    </MenuItem>
                    <MenuItem value="TALTransformers Global Youth Social Innovation Challenge on Health Tech">
                      TALTransformers Global Youth Social Innovation Challenge
                      on Health Tech
                    </MenuItem>
                    <MenuItem value="ATA-TALTransformers Entrepreneurship Pitch Fest 2024">
                      ATA-TALTransformers Entrepreneurship Pitch Fest 2024
                    </MenuItem>
                    <MenuItem value="TTA TALTransformers Youth Social Entrepreneurship Pitch Fest 2024">
                      TTA TALTransformers Youth Social Entrepreneurship Pitch
                      Fest 2024
                    </MenuItem>
                    <MenuItem value="TALTransformers Global Youth Social Innovation Challenge on Agri Tech">
                      TALTransformers Global Youth Social Innovation Challenge
                      on Agri Tech
                    </MenuItem>
                    {/* <MenuItem value="student-blog">Student Blog</MenuItem> */}
                  </Select>
                </FormControl>
                <div className={styles.searchbar}>
                  <div className={styles.search_admin}>
                    <InputBase
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search" }}
                      onKeyPress={(e) => handleEnterClick(e)}
                    />
                    <SearchRounded
                      style={{ margin: "0 4px", height: "30px" }}
                    />
                  </div>

                  {isSearchMode && (
                    <p className={styles.result}>
                      Search Results for
                      <span
                        style={{
                          padding: "3px 10px 5px 10px",
                          marginLeft: "10px",
                          fontWeight: " bold",
                          color: "#686868",
                          background: "#f8f8f8",
                        }}
                      >
                        {keyword}
                      </span>
                      <IconButton
                        edge="start"
                        aria-label="close"
                        style={{
                          padding: "3px",
                          color: "rgb(205, 38, 39)",
                          position: "relative",
                          top: "-2px",
                          margin: "0 0 0 10px",
                          border: "1px solid #ededed",
                        }}
                      >
                        <Tooltip title="Clear Search Results">
                          <CloseIcon onClick={clearSearchResultsHandler} />
                        </Tooltip>
                      </IconButton>
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
          {isAdmin &&
            !isConfiguration &&
            !isMentor &&
            !isJudge &&
            !isViewApplication &&
            allocationStatus !== "all" &&
            allocationStatus !== "student-blog" && (
              <div style={{ marginLeft: "15px", marginBottom: "10px" }}>
                <h5>
                  Number of Ideas submitted: {ideasSubmittedCount} &nbsp; &nbsp;
                  &nbsp; Number of Ideas saved: {ideasSavedCount}
                </h5>
              </div>
            )}
          {isAdmin && allocationStatus === "student-blog" && (
            <div
              style={{
                marginLeft: "15px",
                marginBottom: "10px",
                marginTop: "-55px",
              }}
            >
              <h5>Number of Blogs submitted: {blogsCount}</h5>
            </div>
          )}
        </Container>
      </div>

      <Container MaxWidth="lg">
        <div className="myDonations-container">
          <div className="menu-container">
            <div
              style={{
                display: !isMentor && !isJudge ? "flex" : "flex",
              }}
            >
              {closeSideMenu && (
                <div style={{ marginRight: "10px" }}>
                  <SideMenu />
                </div>
              )}
              <div style={{ padding: "0 10px" }}>
                {!isMentor && !isJudge ? (
                  <>
                    {isViewApplication && document ? (
                      viewApplication()
                    ) : isConfiguration ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FormControl component="fieldset">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={enableCertificateView}
                                  onChange={(e) => {
                                    setEnableCertificateView(
                                      !enableCertificateView
                                    );
                                  }}
                                  name="enableViewCertificate"
                                />
                              }
                              label="Certificate View"
                            />

                            <FormControlLabel
                              control={
                                <Switch
                                  checked={enableCreateTeam}
                                  onChange={(e) => {
                                    setEnableCreateTeam(!enableCreateTeam);
                                  }}
                                  name="enableTeamCreation"
                                />
                              }
                              label="Team Creation"
                            />

                            <FormControlLabel
                              control={
                                <Switch
                                  checked={enableCreateIdea}
                                  onChange={(e) => {
                                    setEnableCreateIdea(!enableCreateIdea);
                                  }}
                                  name="enableIdeaCreation"
                                />
                              }
                              label="Idea Creation"
                            />

                            <FormControlLabel
                              control={
                                <Switch
                                  checked={enableSubmitIdea}
                                  onChange={(e) => {
                                    setEnableSubmitIdea(!enableSubmitIdea);
                                  }}
                                  name="enableIdeaSubmission"
                                />
                              }
                              label="Idea Submission"
                            />
                          </FormGroup>

                          <Button
                            style={{
                              borderRadius: "5px",
                              border: "none",
                              color: "white",
                              height: "55px",
                              margin: "0",
                              backgroundColor: "var(--tal_primary) ",
                            }}
                            onClick={handleConfiguration}
                          >
                            Save
                          </Button>
                        </FormControl>
                      </div>
                    ) : (
                      displayTable(
                        allocationStatus === "all" ? users : donationRequests,
                        isJudge,
                        totalRecords,
                        rowsPerPage,
                        page
                      )
                    )}
                  </>
                ) : isViewApplication && document ? (
                  viewApplication()
                ) : requestsLoading ? (
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Loading...
                  </Typography>
                ) : !requestsLoading &&
                  donationRequests &&
                  donationRequests.length === 0 ? (
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No ideas found...
                  </Typography>
                ) : (
                  donationRequests &&
                  donationRequests.length > 0 &&
                  donationRequests.map((document, index) => {
                    return (
                      <Grid
                        container
                        className="content"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={12} sm={4} md={2} className="img100">
                          <Typography>
                            <img
                              src={
                                document.defaultImageUrl
                                  ? document.defaultImageUrl
                                  : "/images/default-thumbnail.jpeg"
                              }
                              className="img-fluid"
                              alt="Mentor"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={5} md={6}>
                          <Typography style={{ marginBottom: "15px" }}>
                            <p className="text-ucase">
                              Submitted on{" "}
                              {moment(document.createdAt).format(
                                DATE_TIME_FORMAT
                              )}
                            </p>
                            <p>
                              {" "}
                              <strong className=" text-ucase">
                                Title : {document.title}
                              </strong>
                            </p>
                            <p>
                              <strong className=" text-ucase">
                                Submitted For : {document.requested_for}
                              </strong>
                            </p>
                            <p>
                              <strong className=" text-ucase">
                                Track: {document.request_type}
                              </strong>
                              |
                              <strong className="text-ucase">
                                &nbsp;Team Name:{" "}
                                {document &&
                                  document.teamId &&
                                  document.teamId.name}
                              </strong>
                              <strong className="text-ucase">
                                &nbsp;Team Size:{" "}
                              </strong>
                              {document && document.shipping_address && (
                                <strong className="text-ucase">
                                  &nbsp;Location:
                                  {document.shipping_address.city !== null &&
                                    document.shipping_address.city +
                                      "," +
                                      document.shipping_address.state !==
                                      null &&
                                    document.shipping_address.state +
                                      "," +
                                      document.shipping_address.country !==
                                      null &&
                                    document.shipping_address.country}
                                </strong>
                              )}
                            </p>
                            {/* <Grid item xs={12}>
                            {document &&
                              document.supportedSDGs &&
                              document.supportedSDGs.length > 0 && (
                                <>
                                  <Grid container style={{ gap: "5px" }}>
                                    {document.supportedSDGs.map((data) => {
                                      return (
                                        <Chip
                                          className=" bg-secondary"
                                          label={data && data.toString()}
                                          variant="outlined"
                                          style={{ color: "#fff" }}
                                        />
                                      );
                                    })}
                                  </Grid>
                                </>
                              )}
                          </Grid>{" "} */}
                            <p>{document.title}</p>
                          </Typography>
                        </Grid>
                        <Grid xs={12} md={4} sm={3} item>
                          <div className="view-btn edit-btn">
                            <Button
                              onClick={() => {
                                setDonationRequests([]);
                                setIsViewApplication(true);
                                loadDonationRequests(document._id);
                                setViewId(document._id);
                              }}
                              // href={`/transformers/viewapplication/${props.donationRequestId}`}
                              className="edit-button"
                              style={{
                                textDecoration: "none",
                                marginRight: "10px",
                              }}
                            >
                              View Application
                            </Button>
                            {isJudge &&
                              document &&
                              document.assignedJudges &&
                              document.assignedJudges.length == 0 &&
                              document.status === -2 &&
                              new Date().getTime() <
                                moment(
                                  Date.parse(
                                    process.env.REACT_APP_FINAL_SUBMISSION_DATE
                                  )
                                )
                                  .endOf("day")
                                  .valueOf() && (
                                <Button
                                  onClick={() =>
                                    props.history.push(
                                      `/transformers/SubmitIdea/${document._id}`
                                    )
                                  }
                                  className=" edit-button"
                                >
                                  Edit Application
                                </Button>
                              )}
                          </div>
                        </Grid>
                      </Grid>
                    );
                  })
                )}
              </div>
              <DonationRequestForm
                donationRequestId={donationRequestId}
                open={isOpenDonationRequestModal}
                close={() => setIsOpenDonationRequestModal(false)}
                onSave={updateSavedDonationRequest}
              />
              <JudgesAllocation
                donationRequests={donationRequests}
                setDonationRequests={setDonationRequests}
                judgesList={judgeList}
                ideaIds={ideaIds}
                setIdeaIds={setIdeaIds}
                openOrgModal={openOrgModal}
                onCloseModal={(e) => setOpenOrgModal(false)}
                onAllocate={() => {
                  setOpenOrgModal(false);
                }}
                refreshPage={() => {
                  setRefreshPage(true);
                }}
              />
              <MentorAllocation
                donationRequests={donationRequests}
                setDonationRequests={setDonationRequests}
                mentorsList={mentorList}
                ideaIds={ideaIds}
                setIdeaIds={setIdeaIds}
                openOrgModal={openMentorOrgModal}
                onCloseModal={(e) => setOpenMentorOrgModal(false)}
                onAllocate={() => {
                  setOpenMentorOrgModal(false);
                }}
                refreshPage={() => {
                  setRefreshPage(true);
                }}
              />
            </div>
          </div>
        </div>
      </Container>

      {isPrintpage && (
        <ReadingDownloadIdea
          document={rowData}
          openOrgModal={openOrgModalforDownloadIdea}
          onCloseModal={(e) => setOpenOrgModalforDownloadIdea(false)}
        />
      )}
    </div>
  );
};

export default SubmittedIdeas;
