import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Container,
  Typography,
  Box,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
import { Grid, Select } from "@material-ui/core";
import validator from "validator";
import "./Transformers.scss";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { appContext } from "../../App";
import toastr from "toastr";
import useFormValidation from "../../hooks/useFormValidation";
import { RadioGroup, Radio, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import SideMenu from "./SideMenu";
import Header from "./Header";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  createTeam,
  getTeams,
  inviteTeamMember,
  getTeamMembers,
  deleteTeamMember,
} from "../../utils/api";
import { DATE_TIME_FORMAT_FULL, HOME_PAGE } from "../../utils/utils";
import ShortAddress from "../common/ShortAddress";
import Organization from "../Organization/Organization";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { DescriptionOutlined } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddPhotos from "../common/AddPhotos";
import AddDocument from "../common/AddDocument";
import AddVideo from "../common/AddVideo";
import {
  createDonationRequest,
  updateDonationRequest,
  getDonationRequest,
  getDonationRequests,
} from "../../utils/api";
import {
  faRupeeSign,
  faDollarSign,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import ValidateTransformersDonationRequestState from "./ValidateTransformersDonationRequestState";
import Loader from "../common/Loader";
import validateEmailCampaign from "../donationRequest/validateEmailCampaign";

const GettingStarted = (props) => {
  const { authUser, region, regions, setRegion, orgList, configuration } =
    useContext(appContext);
  const ideasStartDate = process.env.REACT_APP_IDEAS_DISPLAYING_DATE;
  const initialTransformersDonationRequestState = {
    title: "",
    description: "",
    addressSocialChange: "",
    solutionSustainableSocialImpact: "",
    supportFromTALScouts: "",
    externalSupport: "",
    status: "-2",
    cashQuantity: "",
    cashUnit: "",
    quantity: "",
    brief: "",
    fundsRecipientName: "",
    keyTargetMetrics: "",
    socialProblem: "",
    solutionProposing: "",
    targetImpact: "",
    howManyLivesWillItChange: "",
    uniqueValueProposition: "",
    targetPartners: "",
    costStructure: "",
    revenueStreams: "",
    potentialRisks: "",
    isAssignMentor: false,
  };
  const [ideaSubmissionSelection, setIdeaSubmissionSelection] = useState("");
  const [advocacyFlag, setAdvocacyFlag] = useState(false);
  const [philanthropyFlag, setPhilanthropyFlag] = useState(false);
  const [entrepreneurshipFlag, setEntrepreneurshipFlag] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [showingAddVideoModal, setShowingAddVideoModal] = useState(false);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [imageUpload, setImageUpload] = useState(false);
  const [isAtleastOneDocumentUploaded, setIsAtleastOneDocumentUploaded] =
    useState(false);
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [address, setAddress] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [tempAddress, setTempAddress] = useState(null);
  const [name, setName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [trainingMode, setTrainingMode] = useState("");
  const [selectionTraining, setSelectionTraining] = React.useState({});
  const [org, setOrg] = useState("");
  const [selectedSupportedSDGs, setSelectedSupportedSDGs] = useState([]);
  const [team, setTeam] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [pitchDeckVideoUrl, setPitchDeckVideoUrl] = useState("");
  const [document, setDocument] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [teamOwner, setTeamOwner] = useState(false);
  const [supportedSDGs, setSupportedSDGs] = React.useState({
    "No Poverty": false,
    "No Hunger": false,
    "Good Health": false,
    "Innovation and Infrastructure": false,
    "Renewable Energy": false,
    "Good Jobs and Economic Growth": false,
    "Gender Equality": false,
    "Clean Water and Sanitization": false,
    "Responsible Consumption": false,
    "Climate Action": false,
    "Life Below Water": false,
    "Reduced Inequalities": false,
    "Partnerships For the Goals": false,
    "Sustainable Cities and Communities": false,
    "Life on Lands": false,
    "Peace and Justice": false,
    "Quality Education": false,
  });
  const [term1, setTerm1] = useState(false);
  const handleTerm1 = (event) => {
    if (event.target.checked) {
      setTerm1(true);
    } else {
      setTerm1(false);
    }
  };
  const [term2, setTerm2] = useState(false);
  const handleTerm2 = (event) => {
    if (event.target.checked) {
      setTerm2(true);
    } else {
      setTerm2(false);
    }
  };

  const handleChangeIdeaSelection = (event) => {
    let ideasSubmissionValue = event.target.value;
    setAdvocacyFlag(false);
    setPhilanthropyFlag(false);
    setEntrepreneurshipFlag(false);
    setIdeaSubmissionSelection(event.target.value);
    validateUserSubmiitedIdeas(ideasSubmissionValue);
  };

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onSavingDocument = (message) => {
    setIsAtleastOneDocumentUploaded(true);
    toastr.success(message);
  };

  const onSavingVideo = (message) => {
    toastr.success("Video is uploaded successfully");
  };

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
    saveDonationRequest(url);
  };

  const handleOrganization = () => {
    setOpenOrgModal(true);
  };

  const onSavingOrg = () => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };

  const OnSave = () => {
    const saveValues = {};
    const saveErrors = {};
    saveValues.user_id = authUser.unique_id;
    saveValues.sourceOfCreation = "talscouts";
    saveValues.teamId = team;
    saveValues.status = -2;
    saveValues.quantity = values.quantity ? parseInt(values.quantity, 10) : "0";
    saveValues.pitchDeckVideoUrl = pitchDeckVideoUrl;
    if (pitchDeckVideoUrl && !validator.isURL(pitchDeckVideoUrl.trim())) {
      saveErrors.validurl = "Please enter a valid URL";
    }
    setErrors(saveErrors);
    if (saveErrors && Object.keys(saveErrors).length > 0) {
      return;
    }
    updateDonationRequest(saveValues, id)
      .then((response) => {
        setName(id);
        toastr.success("User Idea saved successfully.");
        setErrors({});
      })
      .catch((error) => {
        toastr.error("Error while saving this Idea. " + error.message);
      });
  };
  const saveDonationRequest = (defaultImageUrl) => {
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser && authUser.unique_id,
    };

    updateDonationRequest(finalValues, id)
      .then(() => {
        setImageUpload(true);
        toastr.success("Default profile Image is set for this Idea.");
      })
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for this Idea. " +
            error.message
        );
      });
  };
  const onConfirm = () => {
    formSaveOrUpdate();
  };
  const formSaveOrUpdate = () => {
    const formFinalValues = values;
    const saveErrors = {};
    formFinalValues.user_id = authUser.unique_id;
    formFinalValues.requested_for = ideaSubmissionSelection;
    formFinalValues.isAssignMentor = authUser.isAssignMentor;
    formFinalValues.assignedMentorEmail =
      authUser.mentorInfo && authUser.mentorInfo.email;
    formFinalValues.sourceOfCreation = "talscouts";
    formFinalValues.teamId = team;
    formFinalValues.status = -2;
    formFinalValues.supportedSDGs = selectedSupportedSDGs;
    if (trainingMode === "social advocacy") {
      formFinalValues.request_type = "social advocacy";
      formFinalValues.quantity = "0";
    } else if (trainingMode === "social philanthropy") {
      formFinalValues.request_type = "cash";
      formFinalValues.units = values.cashUnit;
      formFinalValues.region = region._id;
      formFinalValues.city = tempAddress.city;
      formFinalValues.state = tempAddress.state;
      formFinalValues.country = tempAddress.country;
      setAddress(tempAddress);
      formFinalValues.quantity = values.quantity
        ? parseInt(values.quantity, 10)
        : "0";
    } else if (trainingMode === "social entrepreneurship") {
      formFinalValues.request_type = "social entrepreneurship";
      formFinalValues.quantity = "0";
    }
    if (!values.title) {
      saveErrors.title = "Please enter title of the project";
    }
    setErrors(saveErrors);
    if (saveErrors && Object.keys(saveErrors).length > 0) {
      toastr.error("Please enter the mandatory fields ");
      return;
    }
    if (id) {
      //   update donation request based on the existing "id" from state variable
      formFinalValues.shipping_address = {
        city: formFinalValues.city,
        state: formFinalValues.state,
        country: formFinalValues.country,
      };
      updateDonationRequest(formFinalValues, id)
        .then((response) => {
          setName(id);
          toastr.success("User Idea updated successfully.");
          setErrors({});
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setErrors((currentValues) => {
              return {
                ...currentValues,
                title:
                  "This title is already taken. Please change the title and try again.",
              };
            });
          } else {
            toastr.error(
              error.response &&
                error.response.data &&
                error.response.data.message
            );
          }
        });
    } else {
      createDonationRequest(formFinalValues)
        .then((response) => {
          if (response.statusCode === 200) {
            setId(response.data._id);
            setName(id);
            toastr.success("User Idea saved successfully.");
            setErrors({});
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setErrors((currentValues) => {
              return {
                ...currentValues,
                title:
                  "This title is already taken. Please change the title and try again.",
              };
            });
          } else {
            toastr.error(
              error.response &&
                error.response.data &&
                error.response.data.message
            );
          }
        });
    }
  };

  const handleCreateTeam = () => {
    const finalValues = {};
    const errors = {};
    if (teamName.length == 0) {
      errors.teamName = "Please enter a team name ";
    } else if (teamName && teamName.length < 5) {
      errors.teamName = "Please enter atleast 5 characters";
    }
    finalValues.name = teamName;
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      return;
    }
    createTeam(finalValues)
      .then((response) => {
        if (response.statusCode === 200) {
          // setTeamId(response.data._id);
          setTeams((previousValues) => [
            {
              _id: response.data._id,
              name: teamName,
              status: "pending",
            },
            ...previousValues,
          ]);
          setActiveStep(0);
          toastr.success("Your Team is created Successfully ");
          setErrors({});
          setTeamName("");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          setErrors((currentValues) => {
            return {
              ...currentValues,
              title:
                "This title is already taken. Please change the title and try again.",
            };
          });
        } else {
          toastr.error("Please Enter a Unique Team Name");
        }
        setActiveStep(0);
      });
    setValues((currentValues) => {
      return {
        ...currentValues,
        teamName: " ",
      };
    });
  };
  const handleCreateTeamMembers = () => {
    const finalValues = {};
    const errors = {};

    if (!values.teamMember || values.teamMember.length === 0) {
      errors.teamMember = "Please enter E-Mail";
    }
    finalValues.teamId = team;
    finalValues.email = values.teamMember && values.teamMember.trim();
    if (finalValues.email && !validator.isEmail(finalValues.email.trim())) {
      toastr.error("Please enter a valid email");
    } else if (!finalValues.teamId || !finalValues.email) {
      toastr.error("Please select team & enter a valid email");
    } else {
      inviteTeamMember(finalValues)
        .then((response) => {
          if (response.statusCode === 200) {
            setTeamMembers((previousValues) => [
              {
                _id: response.data._id,
                email: values.teamMember,
                status: "pending",
                role: "member",
              },
              ...previousValues,
            ]);
            toastr.success("Invitation sent successfully");
            setErrors({});
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setErrors((currentValues) => {
              return {
                ...currentValues,
                title: "This member already exists in the team",
              };
            });
            toastr.error("This member already exists in the team");
          } else {
            toastr.error(
              error.response &&
                error.response.data &&
                error.response.data.message
            );
          }
        });
      setValues((currentValues) => {
        return {
          ...currentValues,
          teamMember: " ",
        };
      });
    }
  };

  const handleNext = () => {
    const finalValues = {};
    const errors = {};
    if (activeStep === 0) {
      if (!team) {
        errors.team = "Please select at least one team";
      }
      if (selectedSupportedSDGs && selectedSupportedSDGs.length < 1) {
        errors.supportedSDGs = "Please select at least one SDG";
      }
      if (!trainingMode) {
        errors.track = "Please select at least one track";
      }
      if (!ideaSubmissionSelection) {
        errors.ideaSubmissionOption = "Please select at least one option";
      }
      setErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        toastr.error("Please enter the mandatory fields ");
        return;
      }
      setActiveStep(activeStep + 1);
    } else if (activeStep === 1) {
      const errorsIdeaForm = {};
      finalValues.user_id = authUser.unique_id;
      finalValues.requested_for = ideaSubmissionSelection;
      finalValues.isAssignMentor = authUser.isAssignMentor;
      finalValues.assignedMentorEmail =
        authUser.mentorInfo && authUser.mentorInfo.email;
      finalValues.sourceOfCreation = "talscouts";
      finalValues.teamId = team;
      finalValues.status = -2;
      finalValues.quantity = "0";
      finalValues.title = values.title;
      finalValues.description = values.description;

      if (!values.title) {
        errorsIdeaForm.title = "Please enter title of the project";
      }

      if (!values.description) {
        errorsIdeaForm.description = "Please enter description";
      }

      var selectedSdgs = [];
      for (var key in supportedSDGs) {
        if (supportedSDGs[key] === true) {
          selectedSdgs.push(key);
        }
      }
      finalValues.supportedSDGs = selectedSdgs;

      if (trainingMode === "social advocacy") {
        if (!selectedSupportedSDGs || selectedSupportedSDGs.length == 0) {
          errorsIdeaForm.supportedSDGs = "Please select at least one SDG";
        }

        finalValues.request_type = "social advocacy";
        finalValues.addressSocialChange = values.addressSocialChange;
        if (!values.addressSocialChange) {
          errorsIdeaForm.addressSocialChange = "This field is required";
        }
        if (!values.supportFromTALScouts) {
          errorsIdeaForm.supportFromTALScouts = "This field is required";
        }
        if (!values.externalSupport) {
          errorsIdeaForm.externalSupport = "This field is required";
        }
        finalValues.solutionSustainableSocialImpact =
          values.solutionSustainableSocialImpact;
        if (!values.solutionSustainableSocialImpact) {
          errorsIdeaForm.solutionSustainableSocialImpact =
            "This field is required";
        }

        finalValues.supportFromTALScouts = values.supportFromTALScouts;
        finalValues.externalSupport = values.externalSupport;

        setErrors(errorsIdeaForm);
        if (errorsIdeaForm && Object.keys(errorsIdeaForm).length > 0) {
          toastr.error("Please enter the mandatory fields ");
          return;
        }
        setActiveStep(activeStep + 1);
      } else if (trainingMode === "social philanthropy") {
        if (!values.quantity) {
          errorsIdeaForm.quantity = "Please enter amount";
        }
        if (!values.brief) {
          errorsIdeaForm.brief = "This field is required";
        }
        if (values.fundsRecipientName.length === 0) {
          errorsIdeaForm.fundsRecipientName =
            "Please enter Funds Recipient Name";
        }
        if (!values.solutionSustainableSocialImpact) {
          errorsIdeaForm.solutionSustainableSocialImpact =
            "This field is required";
        }
        if (!values.keyTargetMetrics) {
          errorsIdeaForm.keyTargetMetrics = "This field is required";
        }
        if (!values.supportFromTALScouts) {
          errorsIdeaForm.supportFromTALScouts = "This field is required";
        }
        if (
          tempAddress === null ||
          tempAddress.city === null ||
          tempAddress.state === null ||
          tempAddress.country === null
        ) {
          errorsIdeaForm.address = "Please Select your Location";
        }
        if (selectedSupportedSDGs && selectedSupportedSDGs.length < 1) {
          errorsIdeaForm.supportedSDGs = "Please select at least one SDG";
        }
        finalValues.sourceOfCreation = "talscouts";
        finalValues.request_type = "cash";
        finalValues.brief = values.brief;
        if (values.fundsRecipientName === "self") {
          finalValues.orgId = "Self";
        } else {
          finalValues.orgId = values.fundsRecipientName;
        }
        finalValues.fundsRecipientName = values.fundsRecipientName;
        finalValues.solutionSustainableSocialImpact =
          values.solutionSustainableSocialImpact;
        finalValues.keyTargetMetrics = values.keyTargetMetrics;
        finalValues.supportFromTALScouts = values.supportFromTALScouts;

        finalValues.units = values.cashUnit;
        finalValues.region = region._id;
        finalValues.city = tempAddress.city;
        finalValues.state = tempAddress.state;
        finalValues.country = tempAddress.country;
        setAddress(tempAddress);
        finalValues.quantity = values.quantity
          ? parseInt(values.quantity, 10)
          : "0";

        setErrors(errorsIdeaForm);
        if (errorsIdeaForm && Object.keys(errorsIdeaForm).length > 0) {
          toastr.error("Please enter the mandatory fields ");
          return;
        }
        setActiveStep(activeStep + 1);
      } else if (trainingMode === "social entrepreneurship") {
        if (selectedSupportedSDGs && selectedSupportedSDGs.length < 1) {
          errorsIdeaForm.supportedSDGs = "Please select at least one SDG";
        }
        if (!values.socialProblem) {
          errorsIdeaForm.socialProblem = "This field is required";
        }
        if (!values.solutionProposing) {
          errorsIdeaForm.solutionProposing = "This field is required";
        }
        if (!values.targetImpact) {
          errorsIdeaForm.targetImpact = "This field is required";
        }
        if (!values.howManyLivesWillItChange) {
          errorsIdeaForm.howManyLivesWillItChange = "This field is required";
        }
        if (!values.uniqueValueProposition) {
          errorsIdeaForm.uniqueValueProposition = "This field is required";
        }
        if (!values.targetPartners) {
          errorsIdeaForm.targetPartners = "This field is required";
        }
        if (!values.costStructure) {
          errorsIdeaForm.costStructure = "This field is required";
        }
        if (!values.revenueStreams) {
          errorsIdeaForm.revenueStreams = "This field is required";
        }
        if (!values.potentialRisks) {
          errorsIdeaForm.potentialRisks = "This field is required";
        }

        finalValues.request_type = "social entrepreneurship";
        finalValues.socialProblem = values.socialProblem;
        finalValues.solutionProposing = values.solutionProposing;
        finalValues.targetImpact = values.targetImpact;
        finalValues.howManyLivesWillItChange = values.howManyLivesWillItChange;
        finalValues.uniqueValueProposition = values.uniqueValueProposition;
        finalValues.targetPartners = values.targetPartners;
        finalValues.costStructure = values.costStructure;
        finalValues.revenueStreams = values.revenueStreams;
        finalValues.potentialRisks = values.potentialRisks;

        setErrors(errorsIdeaForm);
        if (errorsIdeaForm && Object.keys(errorsIdeaForm).length > 0) {
          toastr.error("Please enter the mandatory fields ");
          return;
        }
        setActiveStep(activeStep + 1);
      }

      if (Object.keys(errorsIdeaForm).length == 0) {
        if (id) {
          finalValues.shipping_address = {
            city: finalValues.city,
            state: finalValues.state,
            country: finalValues.country,
          };
          //update donation request based on the existing "id" from state variable
          updateDonationRequest(finalValues, id)
            .then((response) => {
              setActiveStep(2);
              setName(id);
              toastr.success("User Idea updated successfully.");
              setErrors({});
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
            });
        } else {
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setId(response.data._id);
                setActiveStep(2);
                setName(id);
                toastr.success("User Idea saved successfully.");
                setErrors({});
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
            });
        }
      }
    } else if (activeStep === 2) {
      finalValues.defaultImageUrl = defaultImageUrl;
      finalValues.pitchDeckVideoUrl = pitchDeckVideoUrl;

      if (!isAtleastOneDocumentUploaded) {
        errors.document =
          "pdf/supporting docs are mandatory. Please upload atleast one supporting document.";
      }

      if (pitchDeckVideoUrl && !validator.isURL(pitchDeckVideoUrl.trim())) {
        errors.validurl = "Please enter a valid URL";
      }

      setErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        return;
      }

      const submitForm = window.confirm(
        "Are you sure you want to submit? Once submitted can't be edited."
      );
      if (submitForm) {
        finalValues.status = 0;
        finalValues.user_id = authUser && authUser.unique_id;
        updateDonationRequest(finalValues, id)
          .then(() => {
            toastr.success("User Idea submitted successfully.");
            props.history.push("/transformers/Status");
          })
          .catch((error) => {
            toastr.error("Error while submitting the idea. " + error.message);
          });
      }
    }
  };

  const loadTeams = () => {
    authUser &&
      getTeams(authUser.unique_id)
        .then((response) => {
          if (response) {
            // setTeam([...response.data]);
            const myTeamsList = [];
            response.data &&
              response.data.map((item) => {
                myTeamsList.push({
                  name: item.teamId.name,
                  status: item.status,
                  _id: item.teamId._id,
                });
              });
            setTeams(myTeamsList);
          }
        })
        .catch((error) => {
          toastr.error("Problem in fetching Teams:" + error.message);
        });
  };

  const loadTeamMembers = () => {
    team &&
      getTeamMembers(team)
        .then((response) => {
          if (response) {
            const respData = response.data;
            setTeamMembers([...respData]);
            if (response?.data?.length > 1) {
              trainingMode && validateTeamSubmission(team, trainingMode);
              const teamOwner = respData.some(
                (team) =>
                  team?.userId?._id === authUser?.unique_id &&
                  team.role === "owner"
              );
              if (teamOwner) {
                setTeamOwner(true);
              } else {
                setTeamOwner(false);
              }
            } else {
              setTeamOwner(true);
            }
          }
        })
        .catch((error) => {
          toastr.error("Problem in fetching Team Members: " + error.message);
        });
  };

  const loadDonationRequest = () => {
    setIsLoading(true);
    getDonationRequest(props.match.params.id)
      .then((response) => {
        const donationreqData = response;
        setEditMode(true);
        setDocument(donationreqData);
        setId(donationreqData._id);
        setValues((currentValues) => {
          return {
            ...currentValues,
            title: donationreqData.title,
            description: donationreqData.description,
            addressSocialChange: donationreqData.addressSocialChange,
            solutionSustainableSocialImpact:
              donationreqData.solutionSustainableSocialImpact,
            supportFromTALScouts: donationreqData.supportFromTALScouts,
            externalSupport: donationreqData.externalSupport,
            quantity: parseInt(donationreqData.quantity, 10) || 0,
            brief: donationreqData.brief,
            fundsRecipientName: donationreqData.fundsRecipientName,
            solutionSustainableSocialImpact:
              donationreqData.solutionSustainableSocialImpact,
            keyTargetMetrics: donationreqData.keyTargetMetrics,
            description: donationreqData.description,
            addressSocialChange: donationreqData.addressSocialChange,
            solutionSustainableSocialImpact:
              donationreqData.solutionSustainableSocialImpact,
            socialProblem: donationreqData.socialProblem,
            solutionProposing: donationreqData.solutionProposing,
            targetImpact: donationreqData.targetImpact,
            howManyLivesWillItChange: donationreqData.howManyLivesWillItChange,
            uniqueValueProposition: donationreqData.uniqueValueProposition,
            targetPartners: donationreqData.targetPartners,
            costStructure: donationreqData.costStructure,
            revenueStreams: donationreqData.revenueStreams,
            potentialRisks: donationreqData.potentialRisks,
            supportedSDGs: donationreqData.supportedSDGs,
            trainingMode: donationreqData.request_type,
          };
        });
        setSelectionTraining({
          value: donationreqData.request_type,
        });
        setTeam(donationreqData.teamId._id);
        // setTeamName(donationreqData.teamId.name);
        //set existings sdg values
        donationreqData.supportedSDGs.map((item) => {
          supportedSDGs[item] = true;
        });

        //set idea type
        if (donationreqData.request_type == "cash") {
          setTrainingMode("social philanthropy");
        } else if (donationreqData.request_type == "social advocacy") {
          setTrainingMode("social advocacy");
        } else if (donationreqData.request_type == "social entrepreneurship") {
          setTrainingMode("social entrepreneurship");
        }
        setIdeaSubmissionSelection(donationreqData?.requested_for);
        const supportedSDGsAsArray = getKeyByValue(supportedSDGs, true);
        setSupportedSDGs(supportedSDGs);
        setSelectedSupportedSDGs(supportedSDGsAsArray);
        setPitchDeckVideoUrl(
          donationreqData && donationreqData.pitchDeckVideoUrl
        );
        setDefaultImageUrl(donationreqData.defaultImageUrl);
        if (donationreqData.shipping_address) {
          setAddress({
            city: donationreqData.shipping_address.hasOwnProperty("city")
              ? donationreqData.shipping_address.city
              : "",
            state: donationreqData.shipping_address.hasOwnProperty("state")
              ? donationreqData.shipping_address.state
              : "",
            country: donationreqData.shipping_address.hasOwnProperty("country")
              ? donationreqData.shipping_address.country
              : "",
          });
        }
        setOrg(org.orgId && org.orgId._id);
        //TODO: Ideally we should fetch existing documents and check if there is atleast one document uploaded.
        // Temporarily, in the edit flow, we are setting the value to true
        if (props.match.params.id) {
          setIsAtleastOneDocumentUploaded(true);
        }
        setIsLoading(false);
        validateUserSubmiitedIdeas(donationreqData?.requested_for);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Submit Idea:" + error.message);
      });
  };

  useEffect(() => {
    if (authUser && authUser.unique_id && props.match.params.id) {
      loadDonationRequest();
    } else {
      setActiveStep(0);
      setEditMode(false);
      setValues("");
      setId("");
      setSelectionTraining({});
      setTeam("");
      setTrainingMode("");
      setIdeaSubmissionSelection("");
      setOrg("");
      setSupportedSDGs({});
      setSelectedSupportedSDGs([]);
      setPitchDeckVideoUrl("");
      setDefaultImageUrl("");
      setAddress(null);
      setIsAtleastOneDocumentUploaded(false);
      setAdvocacyFlag(false);
      setPhilanthropyFlag(false);
      setEntrepreneurshipFlag(false);
    }
  }, [authUser, props.match.params.id]);

  useEffect(() => {
    if (region && region.currency) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          cashUnit: region.currency,
          cashQuantity: 0,
        };
      });
    }
  }, [region]);

  useEffect(() => {
    loadTeams();
    setTeams([]);
    // if (authUser && authUser.unique_id) {
    //   if (authUser.isPaidBootcamp) {
    //     setIdeaSubmissionSelection("");
    //   } else {
    //     setIdeaSubmissionSelection(
    //       "ou-social-entrepreneurship-pitch-fest-2023"
    //     );
    //     validateUserSubmiitedIdeas(
    //       "ou-social-entrepreneurship-pitch-fest-2023"
    //     );
    //   }
    // }
  }, [authUser]);

  useEffect(() => {
    if (team.length == 0) {
      setTeamMembers([]);
    }
    team && loadTeamMembers();
  }, [team]);

  const validateUserSubmiitedIdeas = (ideasSubmissionValue) => {
    setIsLoading(true);
    getDonationRequests(
      "",
      "",
      "",
      authUser.unique_id,
      JSON.stringify([
        { request_type: "social advocacy" },
        { request_type: "social entrepreneurship" },
        { request_type: "cash", sourceOfCreation: "talscouts" },
      ]),
      "",
      "-2,0,1,2",
      true,
      ideasStartDate,
      "",
      ideasSubmissionValue
    )
      .then((response) => {
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length >= 0
        ) {
          response.data.map((donationRequest) => {
            if (donationRequest?.request_type === "social advocacy") {
              setAdvocacyFlag(true);
              trainingMode === "social advocacy" && setTrainingMode("");
            } else if (
              donationRequest?.request_type === "social entrepreneurship"
            ) {
              setEntrepreneurshipFlag(true);
              trainingMode === "social entrepreneurship" && setTrainingMode("");
            } else if (donationRequest?.request_type === "cash") {
              setPhilanthropyFlag(true);
              trainingMode === "social philanthropy" && setTrainingMode("");
            } else {
              setAdvocacyFlag(false);
              setPhilanthropyFlag(false);
              setEntrepreneurshipFlag(false);
            }
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setAdvocacyFlag(false);
          setPhilanthropyFlag(false);
          setEntrepreneurshipFlag(false);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching Submit Idea:" + error.message);
      });
  };

  const validateTeamSubmission = (teamName, trainingMode) => {
    setIsLoading(true);
    getDonationRequests(
      "",
      "",
      "",
      "",
      trainingMode === "social philanthropy" ? "cash" : trainingMode,
      // trainingMode,
      "",
      "-2,0,1,2",
      true,
      ideasStartDate,
      "",
      ideaSubmissionSelection,
      teamName
    )
      .then((response) => {
        if (
          response.data &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          toastr.error(
            "An idea has already been submitted or saved with the chosen team under this track."
          );
          setTeam("");
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Submit Idea:" + error.message);
      });
  };

  useEffect(() => {
    if (authUser && !authUser.isProfileCompleted) {
      props.history.push("/transformers/ParticipantProfile");
    }
  }, [authUser]);

  const handleDeleteTeamMember = (inputData) => {
    if (!inputData || !inputData._id) {
      toastr.error("Invalid member details");
    } else if (
      window.confirm(
        "Are you sure you want to delete Team Member - " + inputData.email
      )
    ) {
      deleteTeamMember(inputData._id)
        .then(() => {
          setTeamMembers(
            teamMembers.filter((item) => item._id != inputData._id)
          );
          toastr.success("The Team Member is deleted successfully");
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] == value);
  }

  const handleChangeSdgs = (event) => {
    const currentSelected = {
      ...supportedSDGs,
      [event.target.name]: event.target.checked,
    };
    const currentSelectedItemsAsArray = getKeyByValue(currentSelected, true);
    setSupportedSDGs(currentSelected);
    setSelectedSupportedSDGs(currentSelectedItemsAsArray);
  };

  const handleChangeTrainingMode = (event, value) => {
    event.persist();
    const name = event.target.name;
    setSelectionTraining({ ...selectionTraining, [name]: value });
    setTrainingMode(value);
    setShowButton(true);
    name !== "" && team !== "" && validateTeamSubmission(team, value);
  };

  const handleTeam = (event) => {
    setTeam(event.target.value);
  };
  const currencyChangeHandler = (event) => {
    setRegion(regions.find((region) => event.target.value === region.currency));
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(
      initialTransformersDonationRequestState,
      ValidateTransformersDonationRequestState,
      onConfirm
    );

  return (
    <>
      {activeStep === 0 && (
        <Box>
          <div>
            <Header />
            <div className="desktop-menu">
              <SideMenu />
            </div>
            <Container maxWidth="xl" className="tal-container">
              <Box component="h3" mt={2} mb={2}>
                {editMode
                  ? "Your selected track and team details"
                  : "Select Your Track and Add Team Details"}
              </Box>
              <Grid item sm={12} xs={12}>
                <b>Submission For</b>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  style={{ width: "100%", paddingLeft: "10px" }}
                  error={errors && errors.ideaSubmissionOption}
                >
                  {/* <Tooltip title="This field is disabled during edit mode"> */}
                  <RadioGroup
                    row
                    value={ideaSubmissionSelection}
                    onChange={handleChangeIdeaSelection}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    disabled={editMode}
                  >
                    {/* {authUser &&
                      authUser.isPaidBootcamp &&
                      (!editMode ||
                      (editMode &&
                        ideaSubmissionSelection === "bootcamp")) && (
                      <FormControlLabel
                        value="bootcamp"
                        control={<Radio />}
                        label="Boot Camp"
                        // disabled={editMode}
                      />
                    )} */}
                    {/* {((editMode &&
                      ideaSubmissionSelection ===
                        "ou-social-entrepreneurship-pitch-fest-2023") ||
                      !editMode) && (
                      <FormControlLabel
                        value="ou-social-entrepreneurship-pitch-fest-2023"
                        control={<Radio />}
                        label="Social Entrepreneurship Pitchfest"
                        // disabled={editMode}
                      />
                    )} */}
                    {/* {((editMode &&
                      ideaSubmissionSelection === "student-blog") ||
                      !editMode) && (
                      <FormControlLabel
                        value="student-blog"
                        control={<Radio />}
                        label="Student Blog"
                        // disabled={editMode}
                      />
                    )} */}
                    {((editMode &&
                      ideaSubmissionSelection ===
                        "TALYouth Boot Camp On TALKindness Day 2024") ||
                      !editMode) && (
                      <FormControlLabel
                        value="TALYouth Boot Camp On TALKindness Day 2024"
                        control={<Radio />}
                        label="TALYouth Boot Camp On TALKindness Day 2024"
                        // disabled={editMode}
                      />
                    )}
                    {((editMode &&
                      ideaSubmissionSelection ===
                        "Social Entrepreneurship Boot Camp") ||
                      !editMode) && (
                      <FormControlLabel
                        value="Social Entrepreneurship Boot Camp"
                        control={<Radio />}
                        label="Social Entrepreneurship Boot Camp"
                        // disabled={editMode}
                      />
                    )}
                    {/* {((editMode &&
                      ideaSubmissionSelection ===
                        "Transformers Global Youth Social Innovation Challenge on Climate Tech") ||
                      !editMode) && (
                      <FormControlLabel
                        value="Transformers Global Youth Social Innovation Challenge on Climate Tech"
                        control={<Radio />}
                        label="Transformers Global Youth Social Innovation Challenge on Climate Tech"
                        // disabled={editMode}
                      />
                    )}
                    {((editMode &&
                      ideaSubmissionSelection ===
                        "TALTransformers Global Youth Social Innovation Challenge on Health Tech") ||
                      !editMode) && (
                      <FormControlLabel
                        value="TALTransformers Global Youth Social Innovation Challenge on Health Tech"
                        control={<Radio />}
                        label="TALTransformers Global Youth Social Innovation Challenge on Health Tech"
                        // disabled={editMode}
                      />
                    )}
                    {((editMode &&
                      ideaSubmissionSelection ===
                        "ATA-TALTransformers Entrepreneurship Pitch Fest 2024") ||
                      !editMode) && (
                      <FormControlLabel
                        value="ATA-TALTransformers Entrepreneurship Pitch Fest 2024"
                        control={<Radio />}
                        label="ATA-TALTransformers Entrepreneurship Pitch Fest 2024"
                        // disabled={editMode}
                      />
                    )}
                    {((editMode &&
                      ideaSubmissionSelection ===
                        "TTA TALTransformers Social Entrepreneurship Pitch Fest 2024") ||
                      !editMode) && (
                      <FormControlLabel
                        value="TTA TALTransformers Social Entrepreneurship Pitch Fest 2024"
                        control={<Radio />}
                        label="TTA TALTransformers Social Entrepreneurship Pitch Fest 2024"
                        // disabled={editMode}
                      />
                    )}
                    {((editMode &&
                      ideaSubmissionSelection ===
                        "TALTransformers Global Youth Social Innovation Challenge on Agri Tech") ||
                      !editMode) && (
                      <FormControlLabel
                        value="TALTransformers Global Youth Social Innovation Challenge on Agri Tech"
                        control={<Radio />}
                        label="TALTransformers Global Youth Social Innovation Challenge on Agri Tech"
                        // disabled={editMode}
                      />
                    )} */}
                    {/* <>
                    {((editMode && ideaSubmissionSelection === "event2") ||
                      !editMode) && (
                      <FormControlLabel
                        value="event2"
                        control={<Radio />}
                        label="Event 2"
                        // disabled={editMode}
                      />
                    )}
                    {((editMode && ideaSubmissionSelection === "event3") ||
                      !editMode) && (
                      <FormControlLabel
                        value="event3"
                        control={<Radio />}
                        label="Event 3"
                        // disabled={editMode}
                      />
                    )}
                    </> */}
                  </RadioGroup>
                  {/* </Tooltip> */}
                  {errors && errors.ideaSubmissionOption && (
                    <Typography className="custom-error">
                      {errors.ideaSubmissionOption}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item sm={12} xs={12}>
                <b>
                  Choose the approach you want to take to make social impact
                </b>
              </Grid>
              <Grid>
                <RadioGroup
                  row
                  value={trainingMode}
                  onChange={handleChangeTrainingMode}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <Grid container spacing={3}>
                    {(!editMode ||
                      (editMode && trainingMode === "social advocacy")) && (
                      <Grid item lg={4} xs={12}>
                        <Tooltip
                          title={
                            !advocacyFlag
                              ? ""
                              : "You have already submitted an idea under this track"
                          }
                        >
                          <div
                            className={
                              !advocacyFlag
                                ? "tsform-check "
                                : "tsform-check disabled-check"
                            }
                          >
                            <div
                              value="mentor"
                              name="mentor"
                              label="Mentor"
                              className={"mentor-mentee-selected"}
                              style={{
                                cursor: "pointer",
                                marginTop: "15px",
                              }}
                            >
                              <div>
                                <FormControlLabel
                                  value="social advocacy"
                                  control={<Radio />}
                                  label=""
                                  disabled={advocacyFlag}
                                />
                              </div>
                              <img
                                src="../../images/SocialAdvocacy.png"
                                alt="Mentor"
                              />
                              <Typography>
                                <strong>Social Advocacy</strong>
                                <p>
                                  Write a blog, give an idea that promotes
                                  social justice, equality & social inclusion
                                </p>
                              </Typography>
                            </div>
                          </div>
                        </Tooltip>
                      </Grid>
                    )}
                    {(!editMode ||
                      (editMode &&
                        trainingMode === "social entrepreneurship")) && (
                      // ideaSubmissionSelection !== "student-blog" &&
                      <Grid item lg={4} xs={12}>
                        <Tooltip
                          title={
                            !entrepreneurshipFlag
                              ? ""
                              : "You have already submitted an idea under this track"
                          }
                        >
                          <div
                            className={
                              !entrepreneurshipFlag
                                ? "tsform-check "
                                : "tsform-check disabled-check"
                            }
                          >
                            <div
                              value="mentor"
                              name="mentor"
                              label="Mentor"
                              className={"mentor-mentee-selected"}
                              style={{
                                cursor: "pointer",
                                marginTop: "15px",
                              }}
                            >
                              <div>
                                <FormControlLabel
                                  value="social entrepreneurship"
                                  control={<Radio />}
                                  label=""
                                  disabled={entrepreneurshipFlag}
                                />
                              </div>
                              <img
                                src="../../images/SocialEntrepreneurship.svg"
                                alt="Mentor"
                              />
                              <Typography>
                                <strong>Social Entrepreneurship</strong>
                                <p>
                                  Develop, fund and implement solutions aimed
                                  towards social, cultural & environmental
                                  issues
                                </p>
                              </Typography>
                            </div>
                          </div>
                        </Tooltip>
                      </Grid>
                    )}
                    {(!editMode ||
                      (editMode && trainingMode === "social philanthropy")) && (
                      // ideaSubmissionSelection !== "student-blog" &&
                      <Grid item lg={4} xs={12}>
                        <Tooltip
                          title={
                            !philanthropyFlag
                              ? ""
                              : "You have already submitted an idea under this track"
                          }
                        >
                          <div
                            className={
                              !philanthropyFlag
                                ? "tsform-check "
                                : "tsform-check disabled-check"
                            }
                          >
                            <div
                              value="mentor"
                              name="mentor"
                              label="Mentor"
                              className={"mentor-mentee-selected"}
                              style={{
                                cursor: "pointer",
                                marginTop: "15px",
                              }}
                            >
                              <div>
                                <FormControlLabel
                                  value="social philanthropy"
                                  control={<Radio />}
                                  label=""
                                  disabled={philanthropyFlag}
                                />
                              </div>
                              <img
                                src="../../images/socialPhilanthropy.svg"
                                alt="Mentor"
                              />
                              <Typography>
                                <strong>Social Philanthropy</strong>
                                <p>
                                  Fund raising for a cause (kindness &
                                  compassion cannot be judged. This track will
                                  not be judged)
                                </p>
                              </Typography>
                            </div>
                          </div>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </RadioGroup>
              </Grid>
              {errors && errors.track && (
                <Typography className="custom_errors">
                  {errors.track}
                </Typography>
              )}

              <Grid
                item
                xs={12}
                className="subtitle"
                style={{ marginTop: "10px" }}
              >
                Create Team and Add members
              </Grid>

              <Grid item xs={12} className="subtitle">
                Create Team <span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid lg={6} xs={12} className="crt-form text-ucase">
                <TextField
                  variant="outlined"
                  className="f100 text-ucase"
                  placeholder="Enter Team Name"
                  name="teamName"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                />
                <Button
                  className="btn btn-create w-25"
                  id="validate_team"
                  type="submit"
                  onClick={handleCreateTeam}
                  disabled={!configuration.enableTeamCreation}
                >
                  Create
                </Button>
              </Grid>
              {errors && errors.teamName && (
                <Typography className="custom-error">
                  {errors.teamName}
                </Typography>
              )}

              <Grid item lg={6} xs={12} className="subtitle">
                Select Team <span style={{ color: "red" }}>*</span>
                <Select
                  className="f100 text-ucase"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={team}
                  onChange={handleTeam}
                  variant="outlined"
                  name="team"
                  // label="Select Team"
                  disabled={editMode}
                >
                  <MenuItem value={""}>Select Team</MenuItem>
                  {teams &&
                    teams.length > 0 &&
                    teams.map((document, index) => {
                      return (
                        <MenuItem value={teams && document._id}>
                          {teams && document.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                {errors && errors.team && (
                  <Typography className="custom-error">
                    {errors.team}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} className="subtitle">
                Add Team Member
              </Grid>
              <Grid lg={6} xs={12} className="crt-form">
                <TextField
                  variant="outlined"
                  className="f100"
                  placeholder="Enter Team Member E-mail"
                  name="teamMember"
                  type="email"
                  value={values.teamMember}
                  onChange={changeHandler}
                />
                {errors && errors.teamMember && (
                  <Typography className="custom-error">
                    {errors.teamMember}
                  </Typography>
                )}
                <Button
                  className="btn btn-create w-25"
                  type="button"
                  id="validate_team"
                  onClick={handleCreateTeamMembers}
                  disabled={!teamOwner}
                >
                  Add
                </Button>
              </Grid>
              <Grid xs={12} className="teams">
                <span>
                  <i class="fa fa-user" me={2} mt={2}></i> Member can be
                  assigned to one team only.
                </span>
                <br />
                {/* <span>
                    <i class="fa fa-user" me={2} mt={2}></i> Only TALScouts registered
                    member can be added.
                </span> */}
              </Grid>

              <Grid item lg={6} xs={12} className="subtitle">
                Your Team Members
                <div className="tal-team-members">
                  {teamMembers &&
                    teamMembers.length > 0 &&
                    teamMembers.map((teamMember, index) => {
                      return (
                        <>
                          <InputLabel
                            name="teamMember"
                            value={teamMember && teamMember.email}
                          >
                            <div> {teamMember && teamMember.email}</div>
                            <div>
                              {teamMember &&
                              teamMember.status === "accepted" ? (
                                <Tooltip title="Accepted">
                                  <IconButton className="del-btn">
                                    <CheckCircleOutlineIcon
                                      style={{ color: "green" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Waiting for Acceptance">
                                  <IconButton className="del-btn">
                                    <ErrorOutlineIcon
                                      style={{ color: "red" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}

                              {teamMember &&
                                teamMember.role &&
                                teamMember.role != "owner" && (
                                  <Tooltip title="Click to Delete Team Member">
                                    <IconButton
                                      className="del-btn"
                                      onClick={() =>
                                        handleDeleteTeamMember(teamMember)
                                      }
                                      disabled={!teamOwner}
                                    >
                                      <CancelIcon style={{ color: "red" }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                            </div>
                          </InputLabel>
                        </>
                      );
                    })}
                </div>
              </Grid>

              <Grid item sm={12} xs={12} className="subtitle">
                Request mentors in the following SDGs{" "}
                <span style={{ color: "red" }}>*</span>
                <Typography component="p">
                  * Mentors will be assigned based on their availability only.
                </Typography>
              </Grid>
              <Grid container>
                <Grid container>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["No Poverty"]}
                          onChange={handleChangeSdgs}
                          name="No Poverty"
                        />
                      }
                      label="1 - NO POVERTY"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["No Hunger"]}
                          onChange={handleChangeSdgs}
                          name="No Hunger"
                        />
                      }
                      label="2 - ZERO HUNGER"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Good Health"]}
                          onChange={handleChangeSdgs}
                          name="Good Health"
                        />
                      }
                      label="3 - GOOD  HEALTH AND WELL-BEING"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Quality Education"]}
                          onChange={handleChangeSdgs}
                          name="Quality Education"
                        />
                      }
                      label="4 - QUALITY EDUCATION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Gender Equality"]}
                          onChange={handleChangeSdgs}
                          name="Gender Equality"
                        />
                      }
                      label="5 - GENDER  EQUALITY"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Clean Water and Sanitization"]
                          }
                          onChange={handleChangeSdgs}
                          name="Clean Water and Sanitization"
                        />
                      }
                      label="6 - CLEAN  WATER  AND SANITATION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Renewable Energy"]}
                          onChange={handleChangeSdgs}
                          name="Renewable Energy"
                        />
                      }
                      label="7 - AFFORDABLE AND  CLEAN ENERGY"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Good Jobs and Economic Growth"]
                          }
                          onChange={handleChangeSdgs}
                          name="Good Jobs and Economic Growth"
                        />
                      }
                      label="8 - DECENT WORK AND ECONOMIC GROWTH"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Innovation and Infrastructure"]
                          }
                          onChange={handleChangeSdgs}
                          name="Innovation and Infrastructure"
                        />
                      }
                      label="9 - INDUSTRY , INNOVATION AND INFRASTRUCTURE"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Reduced Inequalities"]}
                          onChange={handleChangeSdgs}
                          name="Reduced Inequalities"
                        />
                      }
                      label="10 - REDUCED INEQUALATIES"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Sustainable Cities and Communities"]
                          }
                          onChange={handleChangeSdgs}
                          name="Sustainable Cities and Communities"
                        />
                      }
                      label="11 - SUSTAINABLE CITIES AND COMMUNITIES"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Responsible Consumption"]}
                          onChange={handleChangeSdgs}
                          name="Responsible Consumption"
                        />
                      }
                      label="12 - RESPONSIBLE CONSUMPTION AND PRODUCTION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Climate Action"]}
                          onChange={handleChangeSdgs}
                          name="Climate Action"
                        />
                      }
                      label="13 - CLIMATE ACTION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Life Below Water"]}
                          onChange={handleChangeSdgs}
                          name="Life Below Water"
                        />
                      }
                      label="14 - LIFE BELOW WATER"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Life on Lands"]}
                          onChange={handleChangeSdgs}
                          name="Life on Lands"
                        />
                      }
                      label="15 - LIFE  ON LAND"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Peace and Justice"]}
                          onChange={handleChangeSdgs}
                          name="Peace and Justice"
                        />
                      }
                      label="16 - PEACE,JUSTICE AND STRONG INSTITUTIONS"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Partnerships For the Goals"]}
                          onChange={handleChangeSdgs}
                          name="Partnerships For the Goals"
                        />
                      }
                      label="17 - PARTNERSHIP FOR THE GOALS"
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "15px" }}>
                    {errors && errors.supportedSDGs && (
                      <Typography className="custom-error">
                        {errors.supportedSDGs}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} className="getfooter">
                  <Button
                    className="cancle-btn "
                    type="submit"
                    name="save"
                    onClick={(e) => props.history.push(HOME_PAGE)}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="continues-btn"
                    type="submit"
                    name="continue"
                    id="continue_form"
                    onClick={handleNext}
                    disabled={
                      !props.match.params.id &&
                      !showButton &&
                      !entrepreneurshipFlag
                        ? true
                        : false
                    }
                    style={{
                      opacity:
                        !props.match.params.id &&
                        !showButton &&
                        !entrepreneurshipFlag
                          ? 0.5
                          : 1,
                    }}
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Box>
      )}
      {activeStep === 1 && trainingMode === "social advocacy" && (
        <>
          <Header />
          <div className="desktop-menu">
            <SideMenu />
          </div>
          <Container maxWidth="xl" className="tal-container">
            <Grid item xs={12}>
              <img
                src="../../images/Social-Advocacy.jpg"
                alt="Mentor"
                style={{ width: "100%" }}
              />
            </Grid>

            <Box component="h3" mt={2} mb={2}>
              Social Advocacy
            </Box>
            <Box component="p">
              Submit your idea of Social Advocacy to raise awareness of a social
              problem and how you want the society to change to make the world a
              better place for the community you are caring about.
            </Box>
            <Box component="h5" mt={2} mb={2}>
              Follow the TAL Social Enterprise Canvas to Build Your Innovation
              Idea
            </Box>
            {/* <img
              src="../images/TAL Business Canvas.png"
              alt="Enterpre"
              className="width100"
            /> */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Title of Your Startup <span style={{ color: "red" }}>*</span>
                </Box>
                <Box component="p" mb={1}>
                  Please add a self-descriptive title.
                </Box>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.title}
                >
                  <TextField
                    variant="outlined"
                    className="f100"
                    placeholder="Enter your project title"
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={changeHandler}
                    required
                  />
                  <FormHelperText>{errors.title}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Describe the social problem you want to address
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  Please give details of the problem, who are the undeserved
                  community it is impacting, any relevant research and
                  statistics that can support your problem description. (600
                  words)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.description}
                >
                  <TextareaAutosize
                    aria-label="maximum height"
                    rows={8}
                    placeholder="Enter Text"
                    className="f100 textarea-ui"
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={changeHandler}
                  />
                  <FormHelperText>{errors.description}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Describe what social change you want to impact
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  Please describe the impact- What is the change you want to see
                  and in what way the undeserved community will benefit (600
                  words)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.addressSocialChange}
                >
                  <TextareaAutosize
                    aria-label="maximum height"
                    rows={8}
                    placeholder="Enter Text"
                    className="f100 textarea-ui"
                    type="text"
                    name="addressSocialChange"
                    value={values.addressSocialChange}
                    onChange={changeHandler}
                  />
                  <FormHelperText>{errors.addressSocialChange}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Describe your solution and how it creates a sustainable social
                  impact<span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  Please describe the solution you are proposing, how you plan
                  to influence the society to make the change, who you would
                  like to partner to accelerate the social change, how do you
                  plan to advocate for change - blogging, video, podcast, social
                  media and other (600 words)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.solutionSustainableSocialImpact}
                >
                  <TextareaAutosize
                    aria-label="maximum height"
                    rows={8}
                    placeholder="Enter Text"
                    className="f100 textarea-ui"
                    type="text"
                    name="solutionSustainableSocialImpact"
                    value={values.solutionSustainableSocialImpact}
                    onChange={changeHandler}
                  />
                  <FormHelperText>
                    {errors.solutionSustainableSocialImpact}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Select the SDGs your solution supports
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <Grid container>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["No Poverty"]}
                          onChange={handleChangeSdgs}
                          name="No Poverty"
                        />
                      }
                      label="1 - NO POVERTY"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["No Hunger"]}
                          onChange={handleChangeSdgs}
                          name="No Hunger"
                        />
                      }
                      label="2 - ZERO HUNGER"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Good Health"]}
                          onChange={handleChangeSdgs}
                          name="Good Health"
                        />
                      }
                      label="3 - GOOD  HEALTH AND WELL-BEING"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Quality Education"]}
                          onChange={handleChangeSdgs}
                          name="Quality Education"
                        />
                      }
                      label="4 - QUALITY EDUCATION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Gender Equality"]}
                          onChange={handleChangeSdgs}
                          name="Gender Equality"
                        />
                      }
                      label="5 - GENDER  EQUALITY"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Clean Water and Sanitization"]
                          }
                          onChange={handleChangeSdgs}
                          name="Clean Water and Sanitization"
                        />
                      }
                      label="6 - CLEAN  WATER  AND SANITATION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Renewable Energy"]}
                          onChange={handleChangeSdgs}
                          name="Renewable Energy"
                        />
                      }
                      label="7 - AFFORDABLE AND  CLEAN ENERGY"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Good Jobs and Economic Growth"]
                          }
                          onChange={handleChangeSdgs}
                          name="Good Jobs and Economic Growth"
                        />
                      }
                      label="8 -  DECENT WORK AND ECONOMIC GROWTH"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Innovation and Infrastructure"]
                          }
                          onChange={handleChangeSdgs}
                          name="Innovation and Infrastructure"
                        />
                      }
                      label="9 - INDUSTRY , INNOVATION AND INFRASTRUCTURE"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Reduced Inequalities"]}
                          onChange={handleChangeSdgs}
                          name="Reduced Inequalities"
                        />
                      }
                      label="10 - REDUCED INEQUALATIES"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Sustainable Cities and Communities"]
                          }
                          onChange={handleChangeSdgs}
                          name="Sustainable Cities and Communities"
                        />
                      }
                      label="11 - SUSTAINABLE CITIES AND COMMUNITIES"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Responsible Consumption"]}
                          onChange={handleChangeSdgs}
                          name="Responsible Consumption"
                        />
                      }
                      label="12 - RESPONSIBLE CONSUMPTION AND PRODUCTION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Climate Action"]}
                          onChange={handleChangeSdgs}
                          name="Climate Action"
                        />
                      }
                      label="13 - CLIMATE ACTION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Life Below Water"]}
                          onChange={handleChangeSdgs}
                          name="Life Below Water"
                        />
                      }
                      label="14 - LIFE BELOW WATER"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Life on Lands"]}
                          onChange={handleChangeSdgs}
                          name="Life on Lands"
                        />
                      }
                      label="15 - LIFE  ON LAND"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Peace and Justice"]}
                          onChange={handleChangeSdgs}
                          name="Peace and Justice"
                        />
                      }
                      label="16 -PEACE,JUSTICE AND STRONG INSTITUTIONS"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Partnerships For the Goals"]}
                          onChange={handleChangeSdgs}
                          name="Partnerships For the Goals"
                        />
                      }
                      label="17 - PARTNERSHIP FOR THE GOALS"
                    />
                  </Grid>

                  {errors && errors.supportedSDGs && (
                    <Typography className="custom-error">
                      {errors.supportedSDGs}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={4} mb={1} component="h5" className="clrblack">
                  What support Do You Need From TALTransformers?{" "}
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <Box mb={1} component="p">
                  How do you plan to use TALTransformers Community, TALGiving
                  App, TALMedia and TALRadio for advocating your Solution for
                  social change? (500 characters)
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.supportFromTALScouts}
                >
                  <TextField
                    variant="outlined"
                    className="f100"
                    placeholder="Enter text"
                    type="text"
                    name="supportFromTALScouts"
                    value={values.supportFromTALScouts}
                    onChange={changeHandler}
                    required
                  />
                  <FormHelperText>{errors.supportFromTALScouts}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box mt={4} mb={1} component="h5" className="clrblack">
                  Any extrenal support you need- Non-profit or Corporate or
                  Government agencies or Schools or Other Community agencies you
                  want to partner with? <span style={{ color: "red" }}>*</span>
                </Box>
                <Box mb={1} component="p">
                  Please give names of all community partners that can help
                  accelerate the social impact from your solution (500
                  characters)
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.externalSupport}
                >
                  <TextField
                    variant="outlined"
                    className="f100"
                    placeholder="Enter text"
                    type="text"
                    name="externalSupport"
                    value={values.externalSupport}
                    onChange={changeHandler}
                    required
                  />
                  <FormHelperText>{errors.externalSupport}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={12} className="tals_buttons">
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={formSaveOrUpdate}>Save Draft</Button>
                <Button type="submit" onClick={handleNext}>
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      {activeStep === 1 && trainingMode === "social philanthropy" && (
        <>
          <Header />
          <div className="desktop-menu">
            <SideMenu />
          </div>
          <Container maxWidth="xl" className="tal-container">
            <Grid item xs={12}>
              <img
                src="../../images/social-philanthropy.jpg"
                alt="Mentor"
                style={{
                  margin: " 0 10px",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Box component="h3">Social Philanthropy</Box>
                <Typography>
                  Submit your idea of Philanthropy to raise funds to support a
                  specific cause and to serve an underserved community
                </Typography>
                <img
                  src="../../images/TAL-Business-Canvas.png"
                  alt="Enterpre"
                  className="width100"
                />
                <Box mt={2} mb={1} component="h5" className="clrblack">
                  Title of Your Fundraiser
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <Box mb={1} component="p">
                  Please add a self-descriptive title.
                </Box>
                <Grid item md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    error={errors && errors.title}
                  >
                    <TextField
                      variant="outlined"
                      className="f100"
                      placeholder="Enter your project title"
                      type="text"
                      name="title"
                      value={values.title}
                      onChange={changeHandler}
                      required
                    />
                    <FormHelperText>{errors.title}</FormHelperText>
                  </FormControl>
                </Grid>
                <Box mt={2} mb={1} component="h5" className="clrblack">
                  Purpose of Your Fundraiser - Describe the social cause you
                  want to address<span style={{ color: "red" }}>*</span>
                </Box>
                <Typography>
                  Please give details of the problem, who are the underserved
                  community it is impacting, any relevant research and
                  statistics that can support your problem description. (600
                  words)
                </Typography>
                <br />
                <Grid item md={12}>
                  <FormControl
                    style={{ width: "100%" }}
                    error={errors && errors.description}
                  >
                    <TextareaAutosize
                      aria-label="maximum height"
                      rows={8}
                      placeholder="Enter Text"
                      className="f100 textarea-ui"
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={changeHandler}
                    />
                    <FormHelperText>{errors.description}</FormHelperText>
                  </FormControl>{" "}
                </Grid>
                <Box mt={2} mb={1} component="h5" className="clrblack">
                  Goal of Your Fundraiser<span style={{ color: "red" }}>*</span>
                </Box>
                <Box component="p">
                  Please describe the goal - Target amount, what is the plan for
                  these funds and in what way the underserved community will
                  benefit. (600 words)
                </Box>
                <Grid container spacing={3}>
                  <Grid
                    item
                    sm={2}
                    xs={12}
                    style={{
                      marginTop: "8px",

                      // paddingTop: "15px",
                      // paddingLeft: "10px",
                      // paddingBottom:"0px",
                    }}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <Select
                        variant="outlined"
                        style={{
                          paddingTop: "2px",
                          paddingLeft: "5px",
                          paddingBottom: "0px",
                          height: "55px",
                          background: "#fff",
                        }}
                        name="cashUnit"
                        value={region && region.currency}
                        className="currency"
                        onChange={currencyChangeHandler}
                      >
                        {regions &&
                          regions.length > 0 &&
                          regions.map((region) => {
                            return (
                              <MenuItem value={region.currency}>
                                {region.currency === "INR" ? (
                                  <FontAwesomeIcon
                                    color="#059585"
                                    size="2x"
                                    icon={faRupeeSign}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    color="#059585"
                                    size="2x"
                                    icon={faDollarSign}
                                  />
                                )}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl
                      style={{ width: "100%", paddingTop: "10px" }}
                      error={errors && errors.quantity}
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        style={{
                          fontWeight: "300",
                          fontSize: "20px",
                          color: "#000000",
                          width: "100%",
                          background: "#fff",
                        }}
                        name="quantity"
                        placeholder="Amount"
                        value={values.quantity}
                        onChange={changeHandler}
                      />
                      <FormHelperText>{errors.quantity}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item md={12} style={{ marginTop: "16px" }}>
                  <FormControl
                    style={{ width: "100%" }}
                    error={errors && errors.brief}
                  >
                    <TextareaAutosize
                      aria-label="maximum height"
                      rows={10}
                      placeholder="Enter Text"
                      className="f100 textarea-ui"
                      type="text"
                      name="brief"
                      value={values.brief}
                      onChange={changeHandler}
                    />
                    <FormHelperText>{errors.brief}</FormHelperText>
                  </FormControl>{" "}
                </Grid>
                <Box mt={2} mb={1} component="h5" className="clrblack">
                  Beneficiary of Your Fundraiser
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <Box mb={1} component="p">
                  Which organization or community will receive these funds?
                  Please submit name of the organization, location -
                  country/state/city, all supporting documents to be shown to
                  donors.
                </Box>
                <Grid item xs={12} className="new-addresst">
                  <FormControl
                    style={{ width: "100%" }}
                    error={errors && errors.address}
                  >
                    <ShortAddress
                      value={address}
                      onChange={handleChangeAddress}
                    />
                    <FormHelperText>{errors.address}</FormHelperText>
                  </FormControl>

                  <FormControl
                    style={{ width: "100%" }}
                    className="recipient"
                    error={errors && errors.fundsRecipientName}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{ padding: "0 15px", zIndex: "1" }}
                      className="add-bene"
                    >
                      Select/Add Beneficiary
                    </InputLabel>
                    <Select
                      style={{ width: "100%", background: "#fff" }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.fundsRecipientName}
                      onChange={changeHandler}
                      variant="outlined"
                      placeholder="Organization"
                      name="fundsRecipientName"
                      label="Recipient"
                    >
                      <MenuItem value={"self"}>Self</MenuItem>
                      {orgList &&
                        orgList.length > 0 &&
                        orgList.map((org) => {
                          return (
                            <MenuItem value={org && org.orgId && org.orgId._id}>
                              <img
                                src={
                                  org && org.orgId && org.orgId.defaultImageUrl
                                    ? org.orgId.defaultImageUrl
                                    : "/images/orgLogo.png"
                                }
                                alt="orgLogo"
                                width="20px"
                                style={{ marginRight: 8 }}
                              />{" "}
                              {org && org.orgId && org.orgId.orgName}{" "}
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "100%",
                                  textAlign: "end",
                                }}
                              >
                                {org && org.orgId && org.orgId.status === 1 ? (
                                  <CheckCircleOutlineIcon
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <ErrorOutlineIcon style={{ color: "red" }} />
                                )}
                              </span>
                            </MenuItem>
                          );
                        })}
                      <MenuItem value="add" onClick={handleOrganization}>
                        Add Organization
                      </MenuItem>
                    </Select>{" "}
                    <FormHelperText>{errors.fundsRecipientName}</FormHelperText>
                  </FormControl>
                </Grid>
                <br />
                <Box mt={2} mb={1} component="h5" className="clrblack">
                  {" "}
                  Describe how your fundraiser creates a Sustainable social
                  impact solution<span style={{ color: "red" }}>*</span>
                </Box>
                <Box mb={1} component="p">
                  Please describe the solution you are proposing with this
                  fundraiser, is it a short term or a long term solution, who is
                  the target donor community, how do You plan to reach them (600
                  words)
                </Box>
                <Grid item md={12}>
                  <FormControl
                    style={{ width: "100%" }}
                    error={errors && errors.solutionSustainableSocialImpact}
                  >
                    <TextareaAutosize
                      aria-label="maximum height"
                      rows={10}
                      placeholder="Enter Text"
                      className="f100 textarea-ui"
                      type="text"
                      name="solutionSustainableSocialImpact"
                      value={values.solutionSustainableSocialImpact}
                      onChange={changeHandler}
                    />
                    <FormHelperText>
                      {errors.solutionSustainableSocialImpact}
                    </FormHelperText>
                  </FormControl>{" "}
                </Grid>
                <br />
                <Grid item xs={12}>
                  <Box component="h5" mt={2} mb={2} className="clrblack">
                    Select the SDGs your solution supports
                    <span style={{ color: "red" }}>*</span>
                  </Box>
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["No Poverty"]}
                            onChange={handleChangeSdgs}
                            name="No Poverty"
                          />
                        }
                        label="1 - NO POVERTY"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["No Hunger"]}
                            onChange={handleChangeSdgs}
                            name="No Hunger"
                          />
                        }
                        label="2 - ZERO HUNGER"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["Good Health"]}
                            onChange={handleChangeSdgs}
                            name="Good Health"
                          />
                        }
                        label="3 - GOOD  HEALTH AND WELL-BEING"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["Quality Education"]}
                            onChange={handleChangeSdgs}
                            name="Quality Education"
                          />
                        }
                        label="4 - QUALITY EDUCATION"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["Gender Equality"]}
                            onChange={handleChangeSdgs}
                            name="Gender Equality"
                          />
                        }
                        label="5 - GENDER  EQUALITY"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              supportedSDGs["Clean Water and Sanitization"]
                            }
                            onChange={handleChangeSdgs}
                            name="Clean Water and Sanitization"
                          />
                        }
                        label="6 - CLEAN  WATER  AND SANITATION"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["Renewable Energy"]}
                            onChange={handleChangeSdgs}
                            name="Renewable Energy"
                          />
                        }
                        label="7 -  AFFORDABLE AND  CLEAN ENERGY"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              supportedSDGs["Good Jobs and Economic Growth"]
                            }
                            onChange={handleChangeSdgs}
                            name="Good Jobs and Economic Growth"
                          />
                        }
                        label="8 - DECENT WORK AND ECONOMIC GROWTH"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              supportedSDGs["Innovation and Infrastructure"]
                            }
                            onChange={handleChangeSdgs}
                            name="Innovation and Infrastructure"
                          />
                        }
                        label="9 - INDUSTRY , INNOVATION AND INFRASTRUCTURE"
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["Reduced Inequalities"]}
                            onChange={handleChangeSdgs}
                            name="Reduced Inequalities"
                          />
                        }
                        label="10 - REDUCED INEQUALATIES"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              supportedSDGs[
                                "Sustainable Cities and Communities"
                              ]
                            }
                            onChange={handleChangeSdgs}
                            name="Sustainable Cities and Communities"
                          />
                        }
                        label="11 - SUSTAINABLE CITIES AND COMMUNITIES"
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["Responsible Consumption"]}
                            onChange={handleChangeSdgs}
                            name="Responsible Consumption"
                          />
                        }
                        label="12 - RESPONSIBLE CONSUMPTION AND PRODUCTION"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["Climate Action"]}
                            onChange={handleChangeSdgs}
                            name="Climate Action"
                          />
                        }
                        label="13 - CLIMATE ACTION"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["Life Below Water"]}
                            onChange={handleChangeSdgs}
                            name="Life Below Water"
                          />
                        }
                        label="14 - LIFE BELOW WATER"
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["Life on Lands"]}
                            onChange={handleChangeSdgs}
                            name="Life on Lands"
                          />
                        }
                        label="15 - LIFE  ON LAND"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={supportedSDGs["Peace and Justice"]}
                            onChange={handleChangeSdgs}
                            name="Peace and Justice"
                          />
                        }
                        label="16 - PEACE,JUSTICE AND STRONG INSTITUTIONS"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              supportedSDGs["Partnerships For the Goals"]
                            }
                            onChange={handleChangeSdgs}
                            name="Partnerships For the Goals"
                          />
                        }
                        label="17 -PARTNERSHIP FOR THE GOALS"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Box mt={2} mb={1} component="h5" className="clrblack">
                  Key Target Metrics - Social Impact
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <Box mb={1} component="p">
                  How many people do you plan to affect positively with your
                  fundraiser? Which regions? For how long? (300 words)
                </Box>
                <Grid item md={12}>
                  <FormControl
                    style={{ width: "100%" }}
                    error={errors && errors.keyTargetMetrics}
                  >
                    <TextareaAutosize
                      aria-label="maximum height"
                      rows={8}
                      placeholder="Enter Text"
                      className="f100 textarea-ui"
                      type="text"
                      name="keyTargetMetrics"
                      value={values.keyTargetMetrics}
                      onChange={changeHandler}
                    />
                    <FormHelperText>{errors.keyTargetMetrics}</FormHelperText>
                  </FormControl>{" "}
                </Grid>
                <Grid item xs={12}>
                  <Box mt={4} mb={1} component="h5" className="clrblack">
                    What support Do You Need From TALScouts?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Box>
                  <Box mb={1} component="p">
                    How do you plan to use TALScouts Community, TALGiving App,
                    TALMedia and TALRadio for advocating your Solution for
                    social change? (500 characters)
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <FormControl
                    style={{ width: "100%" }}
                    error={errors && errors.supportFromTALScouts}
                  >
                    <TextField
                      variant="outlined"
                      className="f100"
                      placeholder="Enter text"
                      type="text"
                      name="supportFromTALScouts"
                      value={values.supportFromTALScouts}
                      onChange={changeHandler}
                      required
                    />
                    <FormHelperText>
                      {errors.supportFromTALScouts}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={12} className="tals_buttons">
                  <Button onClick={handleBack}>Back</Button>
                  <Button onClick={formSaveOrUpdate}>Save Draft</Button>
                  <Button type="submit" onClick={handleNext}>
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Organization
            openOrgModal={openOrgModal}
            onCloseModal={() => setOpenOrgModal(false)}
            onSavingOrg={onSavingOrg}
          />
        </>
      )}
      {activeStep === 1 && trainingMode === "social entrepreneurship" && (
        <>
          <Header />
          <div className="desktop-menu">
            <SideMenu />
          </div>
          <Container maxWidth="xl" className="tal-container">
            <Grid item xs={12}>
              <img
                src="../../images/photo_6208415054055977657_y.jpg"
                alt="Mentor"
                style={{ width: "100%" }}
              />
            </Grid>
            <Box component="h3" mt={2} mb={2}>
              Social Entrepreneurship
            </Box>
            <Box component="p">
              Submit your idea of Social Entrepreneurship to raise awareness of
              a social problem and how you want the society to change to make
              the world a better place for the community you are caring about.
            </Box>
            <Box component="h5" mt={2} mb={2}>
              Follow the TAL Social Enterprise Canvas to Build Your Innovation
              Idea
            </Box>
            <img
              src="../../images/TAL-Business-Canvas.png"
              alt="Enterpre"
              className="width100"
            />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Title of Your Startup <span style={{ color: "red" }}>*</span>
                </Box>
                <Box component="p" mb={1}>
                  Please add a self-descriptive title.
                </Box>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.title}
                >
                  <TextField
                    variant="outlined"
                    className="f100"
                    placeholder="Enter your project title"
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={changeHandler}
                    required
                  />
                  <FormHelperText>{errors.title}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Which community has a problem and will benefit from your
                  solution?<span style={{ color: "red" }}>*</span>
                </Box>
                <p>Please add a short description (500 characters)</p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.description}
                >
                  <TextField
                    variant="outlined"
                    className="f100"
                    placeholder="Enter Text"
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={changeHandler}
                  />
                  <FormHelperText>{errors.description}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Please describe the social problem you have identified on a
                  local or a global issue<span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  Please give details of the problem and any relevant research
                  and statistics that can support your problem description. (600
                  words)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.socialProblem}
                >
                  <TextareaAutosize
                    aria-label="maximum height"
                    rows={8}
                    placeholder="Enter Text"
                    className="f100 textarea-ui"
                    type="text"
                    name="socialProblem"
                    value={values.socialProblem}
                    onChange={changeHandler}
                  />
                  <FormHelperText>{errors.socialProblem}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Please describe the solution you are proposing?
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  Please describe the solution you are proposing, how you plan
                  to influence the society to make the change, who you would
                  like to partner to accelerate the social change, how do you
                  plan to advocate for change - blogging, video, podcast, social
                  media and other (600 words)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.solutionProposing}
                >
                  <TextareaAutosize
                    aria-label="maximum height"
                    rows={8}
                    placeholder="Enter Text"
                    className="f100 textarea-ui"
                    type="text"
                    name="solutionProposing"
                    value={values.solutionProposing}
                    onChange={changeHandler}
                  />
                  <FormHelperText>{errors.solutionProposing}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Select the SDGs your solution supports
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <Grid container>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["No Poverty"]}
                          onChange={handleChangeSdgs}
                          name="No Poverty"
                        />
                      }
                      label="1 - NO POVERTY"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["No Hunger"]}
                          onChange={handleChangeSdgs}
                          name="No Hunger"
                        />
                      }
                      label="2 - ZERO HUNGER"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Good Health"]}
                          onChange={handleChangeSdgs}
                          name="Good Health"
                        />
                      }
                      label="3 - GOOD  HEALTH AND WELL-BEING"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Quality Education"]}
                          onChange={handleChangeSdgs}
                          name="Quality Education"
                        />
                      }
                      label="4 - QUALITY EDUCATION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Gender Equality"]}
                          onChange={handleChangeSdgs}
                          name="Gender Equality"
                        />
                      }
                      label="5 - GENDER  EQUALITY"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Clean Water and Sanitization"]
                          }
                          onChange={handleChangeSdgs}
                          name="Clean Water and Sanitization"
                        />
                      }
                      label="6 -CLEAN  WATER  AND SANITATION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Renewable Energy"]}
                          onChange={handleChangeSdgs}
                          name="Renewable Energy"
                        />
                      }
                      label="7 -  AFFORDABLE AND  CLEAN ENERGY"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Good Jobs and Economic Growth"]
                          }
                          onChange={handleChangeSdgs}
                          name="Good Jobs and Economic Growth"
                        />
                      }
                      label="8 - DECENT WORK AND ECONOMIC GROWTH"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Innovation and Infrastructure"]
                          }
                          onChange={handleChangeSdgs}
                          name="Innovation and Infrastructure"
                        />
                      }
                      label="9 - INDUSTRY , INNOVATION AND INFRASTRUCTURE"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Reduced Inequalities"]}
                          onChange={handleChangeSdgs}
                          name="Reduced Inequalities"
                        />
                      }
                      label="10 - REDUCED INEQUALATIES"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            supportedSDGs["Sustainable Cities and Communities"]
                          }
                          onChange={handleChangeSdgs}
                          name="Sustainable Cities and Communities"
                        />
                      }
                      label="11 - SUSTAINABLE CITIES AND COMMUNITIES"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Responsible Consumption"]}
                          onChange={handleChangeSdgs}
                          name="Responsible Consumption"
                        />
                      }
                      label="12 - RESPONSIBLE CONSUMPTION AND PRODUCTION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Climate Action"]}
                          onChange={handleChangeSdgs}
                          name="Climate Action"
                        />
                      }
                      label="13 - CLIMATE ACTION"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Life Below Water"]}
                          onChange={handleChangeSdgs}
                          name="Life Below Water"
                        />
                      }
                      label="14 - LIFE BELOW WATER"
                    />
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Life on Lands"]}
                          onChange={handleChangeSdgs}
                          name="Life on Lands"
                        />
                      }
                      label="15 - LIFE  ON LAND"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Peace and Justice"]}
                          onChange={handleChangeSdgs}
                          name="Peace and Justice"
                        />
                      }
                      label="16 - PEACE,JUSTICE AND STRONG INSTITUTIONS"
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={supportedSDGs["Partnerships For the Goals"]}
                          onChange={handleChangeSdgs}
                          name="Partnerships For the Goals"
                        />
                      }
                      label="17 - PARTNERSHIP FOR THE GOALS"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Target Impact<span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  What economic or social impact will you create? (600 words)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.targetImpact}
                >
                  <TextareaAutosize
                    aria-label="maximum height"
                    rows={8}
                    placeholder="Enter Text"
                    className="f100 textarea-ui"
                    type="text"
                    name="targetImpact"
                    value={values.targetImpact}
                    onChange={changeHandler}
                  />
                  <FormHelperText>{errors.targetImpact}</FormHelperText>
                </FormControl>
                <p>
                  How many lives will it change in the next 3 years? (500
                  characters)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.howManyLivesWillItChange}
                >
                  <TextField
                    variant="outlined"
                    className="f100"
                    placeholder="Enter Text"
                    type="text"
                    name="howManyLivesWillItChange"
                    value={values.howManyLivesWillItChange}
                    onChange={changeHandler}
                  />
                  <FormHelperText>
                    {errors.howManyLivesWillItChange}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Please describe your Unique Value Proposition
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  Describe your solution in detail and what makes your solution
                  unique. (600 words)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.uniqueValueProposition}
                >
                  <TextareaAutosize
                    aria-label="maximum height"
                    rows={8}
                    placeholder="Enter Text"
                    className="f100 textarea-ui"
                    type="text"
                    name="uniqueValueProposition"
                    value={values.uniqueValueProposition}
                    onChange={changeHandler}
                  />
                  <FormHelperText>
                    {errors.uniqueValueProposition}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Target Partners<span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  Identify businesses, nonprofits, governments and other
                  entities needed in your solution. (500 characters)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.targetPartners}
                >
                  <TextField
                    variant="outlined"
                    className="f100"
                    placeholder="Enter Text"
                    type="text"
                    name="targetPartners"
                    value={values.targetPartners}
                    onChange={changeHandler}
                  />
                  <FormHelperText>{errors.targetPartners}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Cost Structure<span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  What resources both volunteers and employees/consultants do
                  you need on your team? What is the approximate cost for next 3
                  years? (600 words)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.costStructure}
                >
                  <TextareaAutosize
                    aria-label="maximum height"
                    rows={8}
                    placeholder="Enter Text"
                    className="f100 textarea-ui"
                    type="text"
                    name="costStructure"
                    value={values.costStructure}
                    onChange={changeHandler}
                  />
                  <FormHelperText>{errors.costStructure}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Revenue Streams<span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  How do your expect to generate funds to create social impact?
                  what is your 5 year growth plan? (600 words)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.revenueStreams}
                >
                  <TextareaAutosize
                    aria-label="maximum height"
                    rows={8}
                    placeholder="Enter Text"
                    className="f100 textarea-ui"
                    type="text"
                    name="revenueStreams"
                    value={values.revenueStreams}
                    onChange={changeHandler}
                  />
                  <FormHelperText>{errors.revenueStreams}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box component="h5" mt={2} mb={2} className="clrblack">
                  Potential Risks<span style={{ color: "red" }}>*</span>
                </Box>
                <p>
                  What potential risks do you see for the success of your,
                  social enterprise - operational risk, funding risk, social
                  risk, any other type of risk. (600 words)
                </p>
                <FormControl
                  style={{ width: "100%" }}
                  error={errors && errors.potentialRisks}
                >
                  <TextareaAutosize
                    aria-label="maximum height"
                    rows={8}
                    placeholder="Enter Text"
                    className="f100 textarea-ui"
                    type="text"
                    name="potentialRisks"
                    value={values.potentialRisks}
                    onChange={changeHandler}
                  />
                  <FormHelperText>{errors.potentialRisks}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={12} className="tals_buttons">
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={formSaveOrUpdate}>Save Draft</Button>
                <Button type="submit" onClick={handleNext}>
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      {activeStep === 2 && (
        <>
          <Header />
          <div className="desktop-menu">
            <SideMenu />
          </div>
          <Container maxWidth="xl" className="tal-container">
            <Box mt={2} mb={1} component="h5" className="clrblack">
              Submit Your Elevator Pitch Deck and Video
            </Box>
            <Box mb={1} component="p">
              Please Include Problem, Solution, SDGs Supported, Social Impact
            </Box>
            <div className="request-step-two">
              <div>
                <FormControl className="upload-pic">
                  <Tooltip classes="req-tooltip" open={true}>
                    <img
                      src={
                        defaultImageUrl
                          ? defaultImageUrl
                          : "/images/default-req-img.gif"
                      }
                      alt="default"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        margin: "10px 0",
                      }}
                    />
                  </Tooltip>
                </FormControl>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Button
                        style={{
                          width: "100%",
                          font: "500 12px Roboto",
                          margin: "10px 5px",
                          padding: "20px 5px",
                          background: "transparent",
                          color: "#283E4A",
                          border: "1px solid #283E4A",
                        }}
                        onClick={() => setShowingAddPhotoModal(true)}
                      >
                        <FontAwesomeIcon
                          color="#283E4A"
                          size="1x"
                          style={{ marginRight: 6 }}
                          icon={faFileUpload}
                        />{" "}
                        Add Images
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowingAddVideoModal(true)}
                        style={{
                          background: "transparent",
                          color: "#283E4A",
                          border: "1px solid #283E4A",
                          font: "500 12px Roboto",
                          padding: "20px 5px",
                          margin: "10px 5px",
                          width: "100%",
                        }}
                        component="span"
                      >
                        <FontAwesomeIcon
                          color="#283E4A"
                          size="1x"
                          style={{ marginRight: 6 }}
                          icon={faFileUpload}
                        />{" "}
                        Add Videos
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        className="document-btn"
                        onClick={() => setShowingAddDocumentModal(true)}
                        style={{
                          width: "100%",
                          font: "500 12px Roboto",
                          padding: "20px 5px",
                          margin: "10px 5px",
                          background: "transparent",
                          color: "#283E4A",
                          border: "1px solid #283E4A",
                        }}
                        component="span"
                      >
                        <DescriptionOutlined
                          style={{ fontSize: 14, marginRight: 6 }}
                        />{" "}
                        Upload supporting document(s)
                      </Button>
                      {errors.document && (
                        <FormHelperText style={{ color: "red" }}>
                          {errors.document}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
              {id && (
                <AddPhotos
                  title="Add Images"
                  isOpen={showingAddPhotoModal}
                  onClose={() => setShowingAddPhotoModal(false)}
                  uid={id}
                  collection="DonationRequest"
                  profilephotoURL={defaultImageUrl}
                  onProfilephotoURLChange={updateDefaultImageUrl}
                  canEdit={true}
                  multiple={true}
                  path={window.location.pathname}
                />
              )}
              {id && (
                <AddDocument
                  title="Add Supporting Documents"
                  isOpen={showingAddDocumentModal}
                  onClose={() => setShowingAddDocumentModal(false)}
                  uid={id}
                  collection="DonationRequest"
                  onSavingDocument={onSavingDocument}
                  canEdit={true}
                  allowOnlyPdf={true}
                />
              )}
              {id && (
                <AddVideo
                  title="Add Videos"
                  isOpen={showingAddVideoModal}
                  onClose={() => setShowingAddVideoModal(false)}
                  uid={id}
                  collection="DonationRequest"
                  onSavingDocument={onSavingVideo}
                  canEdit={true}
                  path={window.location.pathname}
                />
              )}
            </div>
            <Grid item xs={12}>
              <Box component="h5" mt={2} mb={2} className="clrblack">
                Video pitch (up to 5 mins)
              </Box>
              <FormControl
                style={{ width: "100%" }}
                error={errors && errors.validurl}
              >
                <TextField
                  variant="outlined"
                  placeholder="URl Link to a YouTube or Vimeo Video"
                  className="f100"
                  name="pitchDeckVideoUrl"
                  value={pitchDeckVideoUrl}
                  onChange={(e) => setPitchDeckVideoUrl(e.target.value)}
                />
                <FormHelperText>{errors.validurl}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="term1"
                    checked={term1}
                    onChange={handleTerm1}
                    required="required"
                  />
                }
                label={
                  <div className="linktag">
                    <span> I Agree with the </span>
                    <a
                      href={`${process.env.REACT_APP_TALGIVING_URL}/users/Terms`}
                      target="blank"
                    >
                      {" "}
                      Terms &amp;conditions
                    </a>
                  </div>
                }
              />
              <br></br>
              <FormControlLabel
                control={
                  <Checkbox
                    name="term2"
                    checked={term2}
                    onChange={handleTerm2}
                    required="required"
                  />
                }
                label="I understand that this submission is final, no further changes will be considered for my idea submission."
              />
            </Grid>
            <Grid item md={12} className="tals_buttons">
              <Button onClick={handleBack}>Back</Button>
              <Button onClick={OnSave}>Save Draft</Button>
              <Button
                type="submit"
                disabled={
                  term1 && term2 && configuration.enableIdeaSubmission
                    ? false
                    : true
                }
                style={{
                  opacity:
                    term1 && term2 && configuration.enableIdeaSubmission
                      ? 1
                      : 0.5,
                }}
                onClick={handleNext}
              >
                Submit Idea(Final)
              </Button>
            </Grid>
          </Container>
        </>
      )}
      <Loader isOpen={isLoading} onClose={() => setIsLoading(false)} />
    </>
  );
};
export default GettingStarted;
